export default class ArticleDeliverDate {
  CatalogCode: number
  Id: number
  Crds: ArticleCrd[]

  constructor(catalogCode: number, deliveryDate: any) {
    this.CatalogCode = catalogCode
    this.Id = deliveryDate.ArticleId || deliveryDate.Id
    this.Crds = deliveryDate.CrdList && deliveryDate.CrdList.length ? deliveryDate.CrdList.map((crd: any) => new ArticleCrd(crd)) : []
  }
}

export class ArticleCrd {
  Id: number
  CrdId: number
  AvailabilityFrom: Date | null
  AvailabilityTo: Date | null
  Status: ZeroOrOneType

  constructor(crd: any) {
    this.Id = crd.Id
    this.CrdId = crd.CrdId
    this.AvailabilityFrom = crd.AvailabilityFrom ? new Date(crd.AvailabilityFrom) : null
    this.AvailabilityTo = crd.AvailabilityTo ? new Date(crd.AvailabilityTo) : null
    this.Status = crd.Status ? 1 : 0
  }
}
