import { isNumber } from 'lodash-es'
import type { Point } from 'chart.js'
import WbTextBox from '../textBox'
import type { FrameSize } from '../frame'
import WbFrame, { frameSizes } from '../frame'
import WbArticleImage from '../articleImage'
import WbArticleDetails from '../articleDetails'
import WbShape from '../shape'
import WbImage from '../image'
import WbLine from '../line'
import WbModelDetails from '../modelDetails'
import type { IWhiteboardTemplate, IWhiteboardTemplateFileMapping, IWhiteboardTemplateOption } from './IWhiteboardTemplate'
import type CatalogDetails from '@/models/catalogDetails'
import { appConstants, whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'
import type MyArticle from '@/models/myArticle'
import appConfig from '@/services/appConfig'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'

class ColorwayMerch implements IWhiteboardTemplate {
  id = whiteboardConstants.frameTemplatesId.colorwayMerch
  name = 'Colorway Merch'
  imgSize: number = 150
  imgHorSpacing: number = 40
  imgVerSpacing: number = 20
  titleHeight: number = 0
  titleFontSize: number = 27
  titlePadding: number = 10
  frameWidth: number = frameSizes.wide.width
  frameHeight: number = frameSizes.wide.height
  logoWidth = 155
  logoHeight = 87
  logoScaling = 20
  uaNameLogoWidth = 300
  uaNameLogoHeight = 38
  uaNameLogoScaling = 50
  headerHeight = 60
  leftMarginForModel = 30
  frameVerticalHeaderWidth = 70
  startPositionX = 0
  startPositionY = 0
  imageHeight: number = 500
  imageWidth: number = 500
  font: string = 'Neue Plak'
  rightMargin = 20
  groupHeaderProp = {
    fontSize: 42,
    fontWeight: 'normal',
    fontStyle: 'normal',
    height: 80,
    topMargin: 30,
    leftMargin: 70,
    rightMargin: 70,
    bottomMargin: 70,
  }

  subGroupheaderProp = {
    fontSize: 42,
    fontWeight: 'normal',
    fontStyle: 'normal',
    height: 80,
    leftMargin: 60,
    rightMargin: 60,
    bottomMargin: 60,
  }

  subSubGroupheaderProp = {
    fontSize: 42,
    fontWeight: 'normal',
    fontStyle: 'normal',
    height: 20,
  }

  headerProp = {
    fontSize: 40,
    fontWeight: 'bold',
    fontStyle: 'normal',
    paddingBetweenTitleLabels: 20,
  }

  modelHeaderProp = {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fill: '#B8B8B8',
    width: 180,
    height: 30,
    minimumWidth: 150, // 180 - modelname + 35 new or c/o text
    verticalSpacing: 60,
  }

  periodProp = {
    verticalSpacing: 10,
  }

  imageDefaultScaleFactor: number = whiteboardConstants.generateSlideImageScaleFactor.medium
  getOptions(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>): IWhiteboardTemplateOption[] {
    const attributes: IMyAttribute[] = []
    for (const key in myAttributes) {
      attributes.push(myAttributes[key])
    }

    // TODO: Implement configurable options
    const options: IWhiteboardTemplateOption[] = []

    options.push({
      name: 'mainGroup',
      label: 'generateFrameDialog.steps.options.mainGroup',
      type: 'list',
      default: myAttributes.CollectionGroup ? 'CollectionGroup' : '',
      required: true,
      clearable: true,
      filterable: true,
      multiple: false,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })
    options.push({
      name: 'subGroup',
      label: 'generateFrameDialog.steps.options.subGroup',
      type: 'list',
      default: myAttributes.Gender ? myAttributes.Collection ? ['Gender', 'Collection'] : ['Gender'] : myAttributes.Collection ? ['Collection'] : [],
      required: true,
      clearable: true,
      filterable: true,
      multiple: true,
      multipleLimit: 2,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })
    options.push({
      name: 'subSubGroup',
      label: 'generateFrameDialog.steps.options.subSubGroup',
      type: 'list',
      default: myAttributes.StyleFamily ? ['StyleFamily'] : [],
      required: true,
      clearable: true,
      filterable: true,
      multiple: true,
      multipleLimit: 2,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })
    const defaultOptionsForDetails: string[] = []
    if (myAttributes.ColorwayCode) {
      defaultOptionsForDetails.push(myAttributes.ColorwayCode.SystemName)
    }
    if (myAttributes.ColorId) {
      defaultOptionsForDetails.push(myAttributes.ColorId.SystemName)
    }
    if (myAttributes.LogoColor_obj) {
      defaultOptionsForDetails.push(myAttributes.LogoColor_obj.SystemName)
    }
    options.push({
      name: 'attributesToDisplayForCarryoverArticles',
      label: 'generateFrameDialog.steps.options.attributesToDisplayForCarryoverArticles',
      type: 'list',
      default: defaultOptionsForDetails,
      required: true,
      clearable: true,
      filterable: true,
      multiple: true,
      multipleLimit: 3,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })
    options.push({
      name: 'attributesToDisplayForNewArticles',
      label: 'generateFrameDialog.steps.options.attributesToDisplayForNewArticles',
      type: 'list',
      default: defaultOptionsForDetails,
      required: true,
      clearable: true,
      filterable: true,
      multiple: true,
      multipleLimit: 3,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    // const availableFrameSizes: { key: FrameSize, label: string }[] = [
    //   { key: 'wide', label: 'Wide' },
    //   { key: 'a4', label: 'A4' },
    //   { key: 'letter', label: 'Letter' },
    // ]

    // options.push({
    //   name: 'frameSize',
    //   label: 'generateFrameDialog.steps.options.frameSize',
    //   type: 'list',
    //   default: 'wide',
    //   required: true,
    //   multiple: false,
    //   data: availableFrameSizes,
    //   valueProp: 'key',
    //   displayProp: 'label',
    // })

    return options
  }

  getFileMapping(): IWhiteboardTemplateFileMapping[] {
    const mapping: IWhiteboardTemplateFileMapping[] = []

    mapping.push({
      name: 'articleNumber',
      column: 'ArticleNumber',
      type: 'articleNumber',
      label: 'generateFrameDialog.steps.mapping.articleNumber',
      required: true,
      autoMap: ['article', 'articlenumber', 'article number', 'artnbr', 'style color code'],
    })
    return mapping
  }

  // TODO: Need to work on loading custom fonts
  async generate(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, articlesData: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, myUsername: string, startPosition: IPoint, excelData?: Record<string, Record<string, string[]>>, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<IWbObject[]> {
    return await this.buildTemplate(catalog, articlesData, options, myAttributes, startPosition, retailPg, wholesalePg, outletPg)
  }

  async buildTemplate(catalog: CatalogDetails, articles: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, startPosition: IPoint, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<IWbObject[]> {
    const objects: IWbObject[] = []
    // const frameSize = options.frameSize
    this.imgSize = (this.imageWidth * this.imageDefaultScaleFactor) / 100
    // this.changePropsAsPerFrameSize(frameSize)
    this.startPositionX = startPosition.x ? startPosition.x : 0
    this.startPositionY = startPosition.y ? startPosition.y : 0
    const priceGroups: Record<string, CatalogPriceGroup | undefined> = {
      wholesale: wholesalePg,
      retail: retailPg,
      outlet: outletPg,
    }
    const headerGroups = await this.generateGroups(catalog, articles, options, myAttributes, priceGroups)
    let groupHeaderStartX = this.startPositionX
    let groupHeaderStartY = this.startPositionY
    const frameList: IWbObject[] = []
    const subHeaderObjects: IWbObject[] = []
    const headerObjects: IWbObject[] = []
    groupHeaderStartY += this.groupHeaderProp.height + this.groupHeaderProp.topMargin
    for (const [, groupValue] of headerGroups) {
      groupHeaderStartX += this.groupHeaderProp.leftMargin
      let subGroupStartX = groupHeaderStartX
      let subGroupStartY = groupHeaderStartY
      let maxSubSubHeaderFramesLeft = subGroupStartX
      subGroupStartX += this.subGroupheaderProp.leftMargin
      for (const [, subHeaderValue] of groupValue.subGroupMap) {
        subGroupStartY += 30
        subGroupStartY += this.subGroupheaderProp.height
        const subSubGroupStartX = subGroupStartX
        let subSubGroupStartY = subGroupStartY

        for (const [subSubGroupKey, subSubHeaderValue] of subHeaderValue.subSubGroupMap) {
          // generate all the frames for each subheader
          // after each sub header x position will remain same only y position will change
          // const headerOpt = {
          //   left: subSubGroupStartX,
          //   top: subSubGroupStartY,
          //   showLabels: false,
          //   width: this.frameWidth - 20,
          //   attributes: [options.subSubGroup],
          //   attributePlaceHolder: '[Blank]',
          //   fontFamily: this.font,
          //   fontSize: this.subSubGroupheaderProp.fontSize,
          //   fontWeight: this.subSubGroupheaderProp.fontWeight,
          //   textAlign: 'left',
          //   lock: false,
          //   fill: '#000000',
          // }
          // objects.push(await WbArticleDetails.loadArticleDetails(subSubHeaderValue.article, myAttributes, headerOpt))
          subSubGroupStartY += this.subSubGroupheaderProp.height
          const subSubHeaderData = await this.generateFramesForSubSubHeader(catalog, myAttributes, subSubHeaderValue, subSubGroupKey, options, subSubGroupStartX, subSubGroupStartY, priceGroups)
          objects.push(...subSubHeaderData.objects)
          frameList.push(...subSubHeaderData.frameObjects)
          subSubGroupStartY += subSubHeaderData.totalHeightIncreased
          if (maxSubSubHeaderFramesLeft < subSubHeaderData.frameLeft) {
            maxSubSubHeaderFramesLeft = subSubHeaderData.frameLeft
          }
        }
        subHeaderObjects.push(...await this.createSubHeaderObjects(subGroupStartX - this.subGroupheaderProp.leftMargin, subGroupStartY - this.subGroupheaderProp.height, maxSubSubHeaderFramesLeft - subSubGroupStartX, subSubGroupStartY - subGroupStartY + this.subGroupheaderProp.height, options.subGroup, subHeaderValue.article, myAttributes, catalog, priceGroups))
        subGroupStartY = subSubGroupStartY + this.subGroupheaderProp.bottomMargin
      }
      subGroupStartX = maxSubSubHeaderFramesLeft + this.subGroupheaderProp.rightMargin
      headerObjects.push(...await this.createGroupHeaderObjects(groupHeaderStartX - this.groupHeaderProp.leftMargin, groupHeaderStartY - this.groupHeaderProp.height - this.groupHeaderProp.topMargin, subGroupStartX - groupHeaderStartX, subGroupStartY - groupHeaderStartY + this.groupHeaderProp.height, options.mainGroup, groupValue.article, myAttributes, catalog, priceGroups))
      groupHeaderStartX = subGroupStartX + this.groupHeaderProp.rightMargin + 10
      // groupHeaderStartY = subGroupStartY + this.groupHeaderProp.bottomMargin + this.groupHeaderProp.topMargin
    }
    objects.push(...frameList)
    objects.push(...subHeaderObjects)
    objects.push(...headerObjects)
    return objects
  }

  async generateGroups(catalog: CatalogDetails, articles: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, priceGroups?: Record<string, CatalogPriceGroup | undefined>) {
    const groups = new Map()
    const groupAttribute = options.mainGroup
    const subGroupAttributes = options.subGroup
    const subSubGroupAttributes = options.subSubGroup
    let attributesForGrouping: string[] = [groupAttribute].concat(options.subGroup).concat(options.subSubGroup).concat(['MerchDepartment', 'StyleFamily', 'Period', 'ArticleLifeCycle', 'ColorwayCode'])
    attributesForGrouping = attributesForGrouping.reduce((acu, attribute) => {
      if (utils.isDefined(myAttributes[attribute])) {
        acu.push(attribute)
      }
      return acu
    }, [] as Array<string>)
    utils.sort(articles, attributesForGrouping)
    for (let i = 0; i < articles.length; i++) {
      const article = articles[i]
      const groupValue = (await utils.getAttributeTypeSpecificValue(myAttributes[groupAttribute], article, appConfig.DB, catalog, priceGroups)).toLowerCase()
      let subGroupValue = ''
      for (let j = 0; j < subGroupAttributes.length; j++) {
        subGroupValue += (await utils.getAttributeTypeSpecificValue(myAttributes[subGroupAttributes[j]], article, appConfig.DB, catalog, priceGroups)).toLowerCase()
      }
      let subSubGroupValue = ''
      for (let j = 0; j < subSubGroupAttributes.length; j++) {
        subSubGroupValue += (await utils.getAttributeTypeSpecificValue(myAttributes[subSubGroupAttributes[j]], article, appConfig.DB, catalog, priceGroups)).toLowerCase()
      }
      if (groups.has(groupValue)) {
        if (groups.get(groupValue).subGroupMap.has(subGroupValue)) {
          if (groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.has(subSubGroupValue)) {
            if (groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.get(subSubGroupValue).modelGroupMap.has(article.ModelNumber)) {
              if (groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.get(subSubGroupValue).modelGroupMap.get(article.ModelNumber).periodMap.has(article.Period)) {
                if (groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.get(subSubGroupValue).modelGroupMap.get(article.ModelNumber).periodMap.get(article.Period).length < 16) {
                  groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.get(subSubGroupValue).modelGroupMap.get(article.ModelNumber).periodMap.get(article.Period).push(article)
                }
              }
              else {
                groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.get(subSubGroupValue).modelGroupMap.get(article.ModelNumber).periodMap.set(article.Period, [article])
              }
            }
            else {
              const periodMap = new Map()
              periodMap.set(article.Period, [article])
              groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.get(subSubGroupValue).modelGroupMap.set(article.ModelNumber, { periodMap, article })
            }
          }
          else {
            const periodMap = new Map()
            periodMap.set(article.Period, [article])
            const modelGroupMap = new Map()
            modelGroupMap.set(article.ModelNumber, { periodMap, article })
            groups.get(groupValue).subGroupMap.get(subGroupValue).subSubGroupMap.set(subSubGroupValue, { modelGroupMap, article })
          }
        }
        else {
          const periodMap = new Map()
          periodMap.set(article.Period, [article])
          const modelGroupMap = new Map()
          modelGroupMap.set(article.ModelNumber, { periodMap, article })
          const subSubGroupMap = new Map()
          subSubGroupMap.set(subSubGroupValue, { modelGroupMap, article })
          groups.get(groupValue).subGroupMap.set(subGroupValue, { subSubGroupMap, article })
        }
      }
      else {
        const periodMap = new Map()
        periodMap.set(article.Period, [article])
        const modelGroupMap = new Map()
        modelGroupMap.set(article.ModelNumber, { periodMap, article })
        const subSubGroupMap = new Map()
        subSubGroupMap.set(subSubGroupValue, { modelGroupMap, article })
        const subGroupMap = new Map()
        subGroupMap.set(subGroupValue, { subSubGroupMap, article })
        groups.set(groupValue, { subGroupMap, article })
      }
    }
    return groups
  }

  async createSubHeaderObjects(left: number, top: number, width: number, height: number, subGroupKeys: Array<string>, article: MyArticle, myAttributes: Record<string, IMyAttribute>, catalog: CatalogDetails, priceGroups: Record<string, CatalogPriceGroup | undefined>) {
    const objects: IWbObject[] = []
    let subGroupValue = ''
    for (let j = 0; j < subGroupKeys.length; j++) {
      if (j !== 0) {
        subGroupValue += ' - '
      }
      const value = await utils.getAttributeTypeSpecificValue(myAttributes[subGroupKeys[j]], article, appConfig.DB, catalog, priceGroups)
      subGroupValue += utils.isDefined(value) && value !== '' ? value : '[Blank]'
    }
    const rectangleWidth = width + this.subGroupheaderProp.rightMargin + this.subGroupheaderProp.leftMargin
    const rectangleScaleX = rectangleWidth / 50
    const rectangleScaleY = (height + this.subGroupheaderProp.bottomMargin) / 50

    objects.push(this.createTextBoxObject(subGroupValue, left, top, width + this.subGroupheaderProp.rightMargin + this.subGroupheaderProp.leftMargin, this.subGroupheaderProp.fontSize, this.subGroupheaderProp.fontWeight, 'center', '#ffffff', '#686868'))
    objects.push(this.createRectangleObject(left, top, 50, 50, rectangleScaleX, rectangleScaleY, '#E2E8F0'))
    return objects
  }

  async createGroupHeaderObjects(left: number, top: number, width: number, height: number, groupKey: string, article: MyArticle, myAttributes: Record<string, IMyAttribute>, catalog: CatalogDetails, priceGroups: Record<string, CatalogPriceGroup | undefined>) {
    const objects: IWbObject[] = []
    const value = await utils.getAttributeTypeSpecificValue(myAttributes[groupKey], article, appConfig.DB, catalog, priceGroups)
    const rectangleWidth = width + this.groupHeaderProp.rightMargin + this.groupHeaderProp.leftMargin
    const rectangleScaleX = rectangleWidth / 50
    const rectangleScaleY = (height + this.groupHeaderProp.bottomMargin + this.groupHeaderProp.topMargin) / 50

    objects.push(this.createTextBoxObject(utils.isDefined(value) && value !== '' ? value : '[Blank]', left, top, width + this.groupHeaderProp.rightMargin + this.groupHeaderProp.leftMargin, this.groupHeaderProp.fontSize, this.groupHeaderProp.fontWeight, 'center', '#ffffff', '#686868'))
    objects.push(this.createRectangleObject(left, top, 50, 50, rectangleScaleX, rectangleScaleY, '#ffffff'))
    return objects
  }

  async generateFramesForSubSubHeader(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>, subSubHeaderData, subSubGroupKey: string, options: Record<string, any>, startPointX: number, startPointY: number, priceGroup?: Record<string, CatalogPriceGroup | undefined>) {
    let frameLeft = startPointX
    let frameTop = startPointY
    let currentFrame = 1
    const objects: IWbObject[] = []
    const frameObjects: IWbObject[] = []
    let frameCount = 0
    let currentFrameObjects: IWbObject[] = []
    let frameStartPointLeft = frameLeft + 10 + this.leftMarginForModel
    let currentTop = frameTop + 25
    let subSubGroupValue = ''
    for (let j = 0; j < options.subSubGroup.length; j++) {
      subSubGroupValue += await utils.getAttributeTypeSpecificValue(myAttributes[options.subSubGroup[j]], subSubHeaderData.article, appConfig.DB, catalog, priceGroup)
    }
    if (subSubGroupValue === '') {
      subSubGroupValue = '[Blank]'
    }

    currentFrameObjects.push(...await this.createFrameHeader(catalog, subSubHeaderData.article, myAttributes, currentTop, frameStartPointLeft, priceGroup))
    currentTop += this.headerHeight
    frameStartPointLeft += this.frameVerticalHeaderWidth
    const modelsPeriodWidthRequiredData: Record<string, Record<string, number>> = {}
    let currentRowMaxModelHeight = 0
    let row = 1
    for (const [modelNumber, modelGroupValue] of subSubHeaderData.modelGroupMap) {
      modelsPeriodWidthRequiredData[modelNumber] = this.findSpacingForAllPeriods(modelGroupValue.periodMap)
    //   modelsDataCalculation[modelNumber] = findtheModelHeight(modelGroupValue, spacing)
    }
    let remainingSpaceInRow = this.frameWidth - this.leftMarginForModel - 10 - this.rightMargin - this.frameVerticalHeaderWidth
    for (const [modelNumber, modelGroupValue] of subSubHeaderData.modelGroupMap) {
      let currentModelTop = currentTop + ((row - 1) * currentRowMaxModelHeight)
      const minimumWidthRequiredByCurrentModel = this.minimumModelWidthRequired(modelGroupValue.periodMap, modelsPeriodWidthRequiredData[modelNumber])
      if (remainingSpaceInRow < minimumWidthRequiredByCurrentModel) {
        frameStartPointLeft = frameLeft + 10 + this.leftMarginForModel + this.frameVerticalHeaderWidth
        currentModelTop += currentRowMaxModelHeight
        row++
        remainingSpaceInRow = this.frameWidth - this.leftMarginForModel - 10 - this.frameVerticalHeaderWidth
      }
      if (row > 2) {
        objects.push(...currentFrameObjects)
        frameObjects.push(new WbFrame(options.frameSize, { children: currentFrameObjects.map(obj => obj.id || ''), name: `${subSubGroupValue + (frameCount === 0 ? '' : `(${frameCount})`)}`, left: frameLeft, top: frameTop, sortOrder: frameCount, lock: true }))
        currentFrameObjects = []
        currentFrame++
        frameCount++
        // even frame
        if (currentFrame % 2 === 0) {
          frameLeft += this.frameWidth + 10
        //   frameTop = startPointY
        }
        else {
          frameLeft = startPointX
          frameTop += this.frameHeight + 10
        }
        frameStartPointLeft = frameLeft + 10 + this.leftMarginForModel
        currentTop = frameTop + 25
        currentFrameObjects.push(...await this.createFrameHeader(catalog, subSubHeaderData.article, myAttributes, currentTop, frameStartPointLeft, priceGroup))
        currentTop += this.headerHeight
        currentModelTop = currentTop
        frameStartPointLeft += this.frameVerticalHeaderWidth
        row = 1
        remainingSpaceInRow = this.frameWidth - this.leftMarginForModel - 10 - this.frameVerticalHeaderWidth
      }

      currentFrameObjects.push(...await this.addModelHeader(modelGroupValue.article, myAttributes, currentModelTop, frameStartPointLeft, minimumWidthRequiredByCurrentModel))
      currentModelTop += this.modelHeaderProp.height
      currentModelTop += 10 // margin beloe modelheader
      let modelLeft = frameStartPointLeft
      for (const [period, articles] of modelGroupValue.periodMap) {
        currentFrameObjects.push(...await this.addPeriodAndArticles(period, articles, options, modelsPeriodWidthRequiredData[modelNumber][period], myAttributes, currentModelTop, modelLeft))
        modelLeft += modelsPeriodWidthRequiredData[modelNumber][period] + this.periodProp.verticalSpacing
      }
      remainingSpaceInRow -= (modelLeft - frameStartPointLeft) < this.modelHeaderProp.minimumWidth ? this.modelHeaderProp.minimumWidth : modelLeft - frameStartPointLeft
      remainingSpaceInRow -= this.modelHeaderProp.verticalSpacing
      frameStartPointLeft = (modelLeft - frameStartPointLeft) < this.modelHeaderProp.minimumWidth ? frameStartPointLeft + this.modelHeaderProp.minimumWidth + this.modelHeaderProp.verticalSpacing : modelLeft + this.modelHeaderProp.verticalSpacing
      currentRowMaxModelHeight = 300// Math.max(currentRowMaxModelHeight, (maxModelHeightInRow + 10 + this.imgSize))
    }
    objects.push(...currentFrameObjects)
    frameObjects.push(new WbFrame(options.frameSize, { children: currentFrameObjects.map(obj => obj.id || ''), name: `${subSubGroupValue + (frameCount === 0 ? '' : `(${frameCount})`)}`, left: frameLeft, top: frameTop, sortOrder: frameCount }))
    frameCount++
    currentFrameObjects = []
    const totalHeightIncreased = frameCount === 1 || frameCount === 2 ? this.frameHeight + 10 : ((Math.ceil(frameCount / 2) * this.frameHeight) + (Math.ceil(frameCount / 2) * 10))
    return { objects, frameObjects, totalHeightIncreased, frameLeft: startPointX + (frameCount === 1 ? this.frameWidth + 10 : 2 * (this.frameWidth + 10)) }
  }

  minimumModelWidthRequired(periodMap, periodsWidth: Record<string, number>) {
    let spacing = (periodMap.size * this.periodProp.verticalSpacing) + ((periodMap.size - 1) * this.periodProp.verticalSpacing)
    spacing += Object.values(periodsWidth).reduce((acc, obj) => acc + obj, 0) as number
    return spacing < this.modelHeaderProp.minimumWidth ? this.modelHeaderProp.minimumWidth : spacing
  }

  async createFrameHeader(catalog: CatalogDetails, article: MyArticle, myAttributes: Record<string, IMyAttribute>, frameTop: number, frameLeft: number, priceGroups?: Record<string, CatalogPriceGroup | undefined>) {
    const objects: IWbObject[] = []
    objects.push(await this.createHeaderLogo(frameTop, frameLeft + 20))
    objects.push(this.createTextBoxObject('FALL/WINTER', frameLeft + 20, frameTop + this.frameHeight / 2 - 195, 60, 12, 'bold', 'center', '#000000', '', 90))
    objects.push(this.createTextBoxObject('COLLECTION', frameLeft + 20, frameTop + this.frameHeight / 2 - 80, 60, 12, 'bold', 'center', '#000000', '', 90))
    objects.push(this.createTextBoxObject('XXXX', frameLeft + 20, frameTop + this.frameHeight / 2 + 30, 60, 12, 'bold', 'center', '#000000', '', 90))
    objects.push(await this.createHeaderNameLogo(frameTop + this.frameHeight - 180, frameLeft + 25))

    const value = await utils.getAttributeTypeSpecificValue(myAttributes.Collection, article, appConfig.DB, catalog, priceGroups)

    objects.push(this.createTextBoxObject(utils.isDefined(value) && value !== '' ? value : '[Blank]', frameLeft + 40, frameTop + 10, this.frameWidth - 150, 20, 'bold', 'left'))
    return objects
  }

  findSpacingForAllPeriods(modelGroupValue) {
    let periodArticlesCountMap = {}
    const spacingForEachPeriod = {}
    for (const [period, articles] of modelGroupValue) {
      periodArticlesCountMap[period] = articles.length
    }
    periodArticlesCountMap = Object.entries(periodArticlesCountMap)
      .sort(([,a], [,b]) => a as number - (isNumber(b) ? b : 0))
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

    let availableSpaceForEachPeriod = (this.frameWidth - this.leftMarginForModel - this.frameVerticalHeaderWidth - 10 - this.rightMargin - 30 - (modelGroupValue.size * 10) - ((modelGroupValue.size - 1) * 10)) / modelGroupValue.size
    const periods = Object.keys(periodArticlesCountMap)
    for (let i = 0; i < periods.length; i++) {
      if (Math.floor(availableSpaceForEachPeriod / this.imgSize) <= periodArticlesCountMap[periods[i]]) {
        spacingForEachPeriod[periods[i]] = availableSpaceForEachPeriod
      }
      else {
        spacingForEachPeriod[periods[i]] = periodArticlesCountMap[periods[i]] * this.imgSize
        const extraSpace = availableSpaceForEachPeriod - periodArticlesCountMap[periods[i]] * this.imgSize
        if (modelGroupValue.size !== i + 1) {
          const spaceAddedToEach = Math.floor(extraSpace / (modelGroupValue.size - (i + 1)))
          availableSpaceForEachPeriod += spaceAddedToEach
        }
      }
    }
    return spacingForEachPeriod
  }

  async addModelHeader(article: MyArticle, myAttributes: Record<string, IMyAttribute>, top: number, left: number, modelWidth: number) {
    const objects: IWbObject[] = []
    const text = article.ModelLifecycle ? article.ModelLifecycle.toString().toLowerCase() === 'carryover' ? 'C/O' : 'NEW' : ''
    objects.push(this.createTextBoxObject(text, left, top, 35, this.modelHeaderProp.fontSize, 'bold', 'left', text === 'C/O' ? '#000000' : '#FF5733'))
    const modelNameHeaderOpt = {
      left: left + 35,
      top,
      showLabels: false,
      width: modelWidth,
      attributes: ['ModelName'],
      attributePlaceHolder: '[Blank]',
      fontFamily: this.font,
      fontSize: this.modelHeaderProp.fontSize,
      fontWeight: this.modelHeaderProp.fontWeight,
      textAlign: 'left',
      lock: false,
      fill: this.modelHeaderProp.fill,
    }
    const modelNameHeaderObject = await WbModelDetails.loadModelDetails(article, modelNameHeaderOpt)
    objects.push(modelNameHeaderObject)
    const headerOpt = {
      left: left + 35,
      top: top + 15,
      showLabels: false,
      width: modelWidth,
      attributes: ['ModelNumber', '_RetailPrice'],
      attributesSeparator: ' / ',
      attributePlaceHolder: '[Blank]',
      fontFamily: this.font,
      fontSize: this.modelHeaderProp.fontSize,
      fontWeight: this.modelHeaderProp.fontWeight,
      textAlign: 'left',
      lock: false,
      fill: this.modelHeaderProp.fill,
    }
    const modelHeaderObject = await WbModelDetails.loadModelDetails(article, headerOpt)
    objects.push(modelHeaderObject)
    return objects
  }

  async addPeriodAndArticles(period: string, articles: MyArticle[], options: Record<string, any>, periodSpacing: number, myAttributes: Record<string, IMyAttribute>, top: number, left: number) {
    const objects: IWbObject[] = []
    objects.push(this.createLineObject(top, left, 20, 90, this.modelHeaderProp.fill))
    objects.push(this.createLineObject(top, left, periodSpacing + 5, 0, this.modelHeaderProp.fill))
    objects.push(this.createLineObject(top, left + periodSpacing + 5, 20, 90, this.modelHeaderProp.fill))
    objects.push(this.createTextBoxObject(period || '', left, top + 5, periodSpacing, 12, 'normal', 'center', this.modelHeaderProp.fill))
    const imageTop = top + 20
    let imageLeft = left + 5

    let overlappingWidth = 0
    let articlesToConsider = articles
    if (periodSpacing < (articles.length * this.imgSize)) {
      overlappingWidth = 70
      const withOverlapWidthCurrentArticlesSpacing = overlappingWidth * articles.length
      if (withOverlapWidthCurrentArticlesSpacing > periodSpacing) {
        // consider less articles as canot be accomodated in space
        const count = Math.floor(periodSpacing / 70)
        articlesToConsider = articles.slice(0, count)
      }
      else if (withOverlapWidthCurrentArticlesSpacing < periodSpacing) {
        overlappingWidth += 2
        while (overlappingWidth * articles.length <= periodSpacing) {
          overlappingWidth += 2
        }
      }
    }
    for (let i = 0; i < articlesToConsider.length; i++) {
      const articleImageObj = await this.createImage(imageTop, imageLeft, articlesToConsider[i], this.imageDefaultScaleFactor)
      objects.push(articleImageObj)
      const headerOpt = {
        left: imageLeft,
        top: imageTop + this.imgSize + 5,
        showLabels: false,
        width: overlappingWidth === 0 ? this.imgSize : overlappingWidth - 35,
        attributes: articlesToConsider[i].ArticleLifecycle ? (articlesToConsider[i].ArticleLifecycle.toString().toLowerCase() === 'carryover' ? options.attributesToDisplayForCarryoverArticles : options.attributesToDisplayForNewArticles) : ['ArticleNumber'],
        attributesSeparator: '\n',
        attributePlaceHolder: '[Blank]',
        fontFamily: this.font,
        fontSize: this.modelHeaderProp.fontSize,
        fontWeight: this.modelHeaderProp.fontWeight,
        textAlign: 'center',
        lock: false,
        fill: this.modelHeaderProp.fill,
      }
      const modelHeaderObject = await WbArticleDetails.loadArticleDetails(articlesToConsider[i], myAttributes, headerOpt)
      objects.push(modelHeaderObject)
      imageLeft += overlappingWidth === 0 ? this.imgSize : overlappingWidth
    }
    return objects
  }

  changePropsAsPerFrameSize(frameSize: FrameSize) {
    this.frameWidth = frameSizes[frameSize].width
    this.frameHeight = frameSizes[frameSize].height
  }

  // objects functions
  createTextBoxObject(text: string, left: number, top: number, width: number, fontSize: number, fontWeight = 'normal', textAlign = 'center', fill = '#000000', backgroundColor = '', angle = 0) {
    return new WbTextBox(text, {
      top,
      left,
      width,
      fill,
      fontFamily: this.font,
      fontSize,
      fontStyle: 'normal',
      fontWeight,
      textAlign,
      lock: false,
      backgroundColor,
      angle,
    })
  }

  createLineObject(top: number, left: number, width: number, angle = 0, stroke = '#000000') {
    const points: Point[] = []
    if (angle === 0) {
      points.push({ x: left, y: top })
      points.push({ x: left + width, y: top })
    }
    else if (angle === 90) {
      points.push({ x: left, y: top })
      points.push({ x: left, y: top + width })
    }
    return new WbLine(points, {
      stroke,
    })
  }

  createRectangleObject(left: number, top: number, width: number, height: number, scaleX = 1, scaleY = 1, fill = 'transparent') {
    return new WbShape('rectangle', {
      top,
      left,
      width,
      height,
      fill,
      opacity: 1,
      scaleX,
      stroke: '#000000',
      scaleY,
      lock: true,
    })
  }

  async createHeaderNameLogo(top: number, left: number) {
    const value = { scale: this.uaNameLogoScaling }
    const options = {
      top,
      left,
      width: this.uaNameLogoWidth,
      height: this.uaNameLogoHeight,
      angle: 90,
      flipX: false,
      flipY: false,
    }
    return await WbImage.loadFromUrl(appConstants.logoPaths.uaNameLogo, options).then((newImg) => {
      newImg.setProp('scale', value)
      return newImg
    })
  }

  async createHeaderLogo(top: number, left: number) {
    const value = { scale: this.logoScaling }
    const options = {
      top,
      left,
      width: this.logoWidth,
      height: this.logoHeight,
      angle: 90,
      flipX: false,
      flipY: false,
    }
    return await WbImage.loadFromUrl(appConstants.logoPaths.uaIcon, options).then((newImg) => {
      newImg.setProp('scale', value)
      return newImg
    })
  }

  async createImage(top: number, left: number, article: MyArticle, imageScaleFactor: number) {
    const scaleValue = { scale: imageScaleFactor }
    return await WbArticleImage.loadArticleImage(article, 500, 500, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle }).then((newImg) => {
      newImg.setProp('scale', scaleValue)
      return newImg
    })
  }
}

export default new ColorwayMerch()
