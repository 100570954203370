import { transform } from 'lodash-es'
import WbTextBox from '../textBox'
import WbFrame from '../frame'
import WbArticleImage from '../articleImage'
import WbArticleDetails from '../articleDetails'
import type { IWhiteboardTemplate, IWhiteboardTemplateFileMapping, IWhiteboardTemplateOption } from './IWhiteboardTemplate'
import type CatalogDetails from '@/models/catalogDetails'
import { whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'
import type MyArticle from '@/models/myArticle'
import appConfig from '@/services/appConfig'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'

class LevisMensBottomsRTFinish implements IWhiteboardTemplate {
  id = whiteboardConstants.frameTemplatesId.LevisMensBottomsRTFinish
  name = 'Levi\'s Men\'s Bottoms RT Finish - Print'
  imgSize: number = 350
  imgHorSpacing: number = 40
  imgVerSpacing: number = 20
  titleFontSize: number = 48
  titleTopPadding: number = 50
  titleLeftPadding: number = 30
  frameWidth: number = 1632
  frameHeight: number = 1056
  headerHeight: number = 200
  font: string = 'Roboto'
  articleAttributeProp = {
    fontWeight: 'normal',
    fontSize: 26,
    textAlign: 'center',
  }

  leftMargin: number = 50
  spacingBetweenFrames = 50
  startPosition = { x: 0, y: 0 }
  getOptions(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>): IWhiteboardTemplateOption[] {
    const attributes: IMyAttribute[] = []
    for (const key in myAttributes) {
      attributes.push(myAttributes[key])
    }

    // TODO: Implement configurable options
    const options: IWhiteboardTemplateOption[] = []
    let defaultAttributesList: string [] = ['MerchPlaceholderCode_Levi', 'ColorwayCodePc9_Levi', 'ProductLifecycleGroup_Levi', 'ShadeOrder_Levi', 'ColorwayDescription_Levi', 'FinishDescription_Levi', 'TargetCluster_Levi', 'InitialUnitCost_Levi']
    defaultAttributesList = defaultAttributesList.filter(attribute => myAttributes[attribute])
    options.push({
      name: 'displayAttributesOnArticleImage',
      label: 'generateFrameDialog.steps.options.displayAttributesOnArticleImage',
      type: 'list',
      default: defaultAttributesList,
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })
    let defaultDisplayAttributesListForNew = ['SharedFinish_Levi', 'ReplacementFinish_Levi', 'BlpNotes_Levi', 'MaterialCodeFaCode_Levi', 'BLPCustomAttribute2_Levi', 'BLPCustomAttribute1_Levi']
    defaultDisplayAttributesListForNew = defaultDisplayAttributesListForNew.filter(attribute => myAttributes[attribute])
    options.push({
      name: 'attributesToDisplayForNewArticles',
      label: 'generateFrameDialog.steps.options.attributesToDisplayForNewArticles',
      type: 'list',
      default: defaultDisplayAttributesListForNew,
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })
    let defaultDisplayAttributesListForCarryover = ['ColorwayName_Levi', 'ColorLookCodePlmLoaderFile_Levi', 'PrimaryFabric_Levi', 'TargetCluster_Levi', 'UnitsBlp_Levi', 'SeasonFirstIntroduced_Levi', 'LastSeasonOffered_Levi', 'UnitCost_Levi']
    defaultDisplayAttributesListForCarryover = defaultDisplayAttributesListForCarryover.filter(attribute => myAttributes[attribute])
    options.push({
      name: 'attributesToDisplayForCarryoverArticles',
      label: 'generateFrameDialog.steps.options.attributesToDisplayForCarryoverArticles',
      type: 'list',
      default: defaultDisplayAttributesListForCarryover,
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    options.push({
      name: 'numberOfArticlesOnEachFrame',
      label: 'generateDialog.steps.options.numberOfArticlesOnEachFrame',
      type: 'number',
      default: 4,
      min: 2,
      max: 10,
      required: false,
    })
    return options
  }

  getFileMapping(): IWhiteboardTemplateFileMapping[] {
    const mapping: IWhiteboardTemplateFileMapping[] = []

    mapping.push({
      name: 'articleNumber',
      column: 'ArticleNumber',
      type: 'articleNumber',
      label: 'generateFrameDialog.steps.mapping.articleNumber',
      required: true,
      autoMap: ['article', 'articlenumber', 'article number', 'artnbr', 'style color code'],
    })
    return mapping
  }

  // TODO: Need to work on loading custom fonts
  async generate(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, articlesData: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, myUsername: string, startPosition: IPoint, excelData?: Record<string, Record<string, string[]>>, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, isExporting: boolean = false): Promise<IWbObject[]> {
    return await this.buildTemplate(catalog, articlesData, options, myAttributes, startPosition, isExporting, retailPg, wholesalePg, outletPg)
  }

  async buildTemplate(catalog: CatalogDetails, articles: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, startPosition: IPoint, isExporting: boolean, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<IWbObject[]> {
    const objects: IWbObject[] = []
    const attributesToDisplayOnImage = options.displayAttributesOnArticleImage
    const frameCount = 0
    this.startPosition = { x: startPosition.x ? startPosition.x : 0, y: startPosition.y ? startPosition.y : 0 }
    const priceGroups: Record<string, CatalogPriceGroup | undefined> = {
      wholesale: wholesalePg,
      retail: retailPg,
      outlet: outletPg,
    }
    this.calculateImageProps(options.numberOfArticlesOnEachFrame || 4)
    const groups = await this.generateGroups(catalog, articles, options, myAttributes, priceGroups)
    for (const [, value] of Object.entries(groups)) {
      objects.push(...await this.generateFrameForEachKey(catalog, value, myAttributes, attributesToDisplayOnImage, options, this.startPosition, frameCount, isExporting))
    }
    return objects
  }

  calculateImageProps(numberOfArticlesOnEachFrame) {
    const spaceAvailableForEachImage = (this.frameWidth - this.leftMargin) / numberOfArticlesOnEachFrame

    this.imgHorSpacing = (spaceAvailableForEachImage * 0.10)
    this.imgSize = spaceAvailableForEachImage - this.imgHorSpacing
    if (this.imgSize > 500) {
      this.imgSize = 500
      this.imgHorSpacing = spaceAvailableForEachImage - this.imgSize
    }
  }

  async generateFrameForEachKey(catalog, groupDetails: Record<string, any>, myAttributes: Record<string, IMyAttribute>, attributesOnImage: string[], options: Record<string, string[]>, startPosition: Record<string, number>, frameCount: number, isExporting: boolean) {
    let frameObjects: IWbObject[] = []
    const objects: IWbObject [] = []
    let frameLeft = startPosition.x
    const frameTop = startPosition.y
    let top = frameTop
    const numberOfArticlesOnEachFrame = Number(options.numberOfArticlesOnEachFrame) || 4
    frameObjects.push(this.createTitleObj(groupDetails.headerText, frameLeft, top))
    top += this.headerHeight
    const articles = groupDetails.articles
    const sortedStateName = ['New', 'Carryover']
    articles.sort((a, b) => sortedStateName.indexOf(a.StateName) - sortedStateName.indexOf(b.StateName))
    for (let index = 0; index < articles.length; index++) {
      const article = articles[index]
      if (index !== 0 && (index % numberOfArticlesOnEachFrame === 0)) {
        // new page
        objects.push(...frameObjects)
        objects.push(new WbFrame('ledger', { children: frameObjects.map(obj => obj.id || ''), name: `${groupDetails.headerText + (frameCount === 0 ? '' : `(${frameCount})`)}`, left: isExporting ? 0 : frameLeft, top: isExporting ? 0 : frameTop, sortOrder: frameCount }))
        frameObjects = []
        startPosition.x += isExporting ? 0 : this.frameWidth + this.spacingBetweenFrames
        frameLeft = startPosition.x
        top = frameTop
        frameObjects.push(this.createTitleObj(groupDetails.headerText, frameLeft, top))
        top += this.headerHeight
      }
      // draw article Images and there details
      const currentImageInRow = (index) % numberOfArticlesOnEachFrame
      const imageLeft = this.leftMargin + frameLeft + currentImageInRow * (this.imgSize + this.imgHorSpacing)
      const articleImageObj = await this.createImage(catalog, top, imageLeft, article, attributesOnImage, isExporting)
      frameObjects.push(articleImageObj)
      const detailsTop = top + this.imgSize + this.imgVerSpacing
      // if (attributesToDisplay.length !== 0) {
      const articleDetailsObject = await this.getArticleDetailsObject(article, article.StateName && article.StateName.toLowerCase() === 'carryover' ? options.attributesToDisplayForCarryoverArticles : options.attributesToDisplayForNewArticles, myAttributes, detailsTop, imageLeft)
      if (isExporting) {
        const obj = articleDetailsObject.toObject() as WbArticleDetails
        obj.text = articleDetailsObject.text
        frameObjects.push(obj)
      }
      else {
        frameObjects.push(articleDetailsObject)
      }
      // }
    }
    objects.push(...frameObjects)
    objects.push(new WbFrame('ledger', { children: frameObjects.map(obj => obj.id || ''), name: `${groupDetails.headerText + (frameCount === 0 ? '' : `(${frameCount})`)}`, left: isExporting ? 0 : frameLeft, top: isExporting ? 0 : frameTop, sortOrder: frameCount }))
    startPosition.x += isExporting ? 0 : this.frameWidth + this.spacingBetweenFrames
    return objects
  }

  async generateGroups(catalog: CatalogDetails, articles: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, priceGroups?: Record<string, CatalogPriceGroup | undefined>) {
    const groups: Record<string, any> = {}
    let attributesForGrouping: string[] = ['ProductPricePositioning_Levi', 'DittoCollection_Levi', 'ProductName_Levi', 'ProductStory_Levi', 'ArticleNumber']
    attributesForGrouping = attributesForGrouping.reduce((acu, attribute) => {
      if (utils.isDefined(myAttributes[attribute])) {
        acu.push(attribute)
      }
      return acu
    }, [] as Array<string>)
    utils.sort(articles, attributesForGrouping)
    for (let i = 0; i < articles.length; i++) {
      const article = articles[i]
      let headerText = ''
      headerText += (await utils.getAttributeTypeSpecificValue(myAttributes.ProductPricePositioning_Levi, article, appConfig.DB, catalog, priceGroups))
      headerText += ' '
      headerText += (await utils.getAttributeTypeSpecificValue(myAttributes.DittoCollection_Levi, article, appConfig.DB, catalog, priceGroups))
      headerText += ': '
      headerText += (await utils.getAttributeTypeSpecificValue(myAttributes.ProductName_Levi, article, appConfig.DB, catalog, priceGroups))
      headerText += ' - '
      headerText += (await utils.getAttributeTypeSpecificValue(myAttributes.ProductStory_Levi, article, appConfig.DB, catalog, priceGroups))
      const key = headerText.toLowerCase()
      if (groups[key]) {
        groups[key].articles.push(article)
      }
      else {
        groups[key] = {
          headerText,
          articles: [article],
        }
      }
    }
    return groups
  }

  async getArticleDetailsObject(article: MyArticle, articleAttributes: string[], myAttributes: Record<string, IMyAttribute>, top: number, left: number, isExporting: boolean = false) {
    const opt = {
      left,
      top,
      width: this.imgSize,
      attributes: articleAttributes,
      showLabels: false,
      fontFamily: this.font,
      fontSize: this.articleAttributeProp.fontSize,
      fontWeight: this.articleAttributeProp.fontWeight,
      textAlign: this.articleAttributeProp.textAlign,
      lock: false,
      fill: '#000000',
    }
    const object = await WbArticleDetails.loadArticleDetails(article, myAttributes, opt, !isExporting)
    return object
  }

  // objects functions
  createTitleObj(title: string, left: number, top: number) {
    return new WbTextBox(title, {
      top: this.titleTopPadding + top,
      left: this.titleLeftPadding + left,
      width: this.frameWidth - 20,
      fill: '#000000',
      fontFamily: this.font,
      fontSize: this.titleFontSize,
      fontStyle: 'normal',
      fontWeight: 'bold',
      textAlign: 'left',
      lock: false,
    })
  }

  async createImage(catalog: CatalogDetails, top: number, left: number, article: MyArticle, attributesOnImage: string[], isExporting: boolean) {
    const scaleValueCalc = (this.imgSize * 100) / 500
    const scaleValue = { scale: scaleValueCalc }
    let configuredColorBackgroundColor = catalog.Config?.NoImageColorCodeForLevisTemplate
    configuredColorBackgroundColor = transform(configuredColorBackgroundColor, (result, val, key) => {
      result[key.toLowerCase()] = val
    })
    const productLifeCycleGroupValue: string = article.ProductLifecycleGroup_Levi?.toString() || 'blank'
    const noImageObjectProperties: Record<string, any> = {}
    noImageObjectProperties.backgroundColor = configuredColorBackgroundColor[productLifeCycleGroupValue.toLowerCase()] || '#ffffff'
    noImageObjectProperties.attributes = attributesOnImage
    noImageObjectProperties.border = { borderColor: utils.isDefined(article.SharedFinish_Levi) ? '#9ad054' : '#000000', strokeWidth: 1 }
    if (isExporting) {
      return await WbArticleImage.getJsonObject(article, 500, 500, scaleValue, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle, noImageObjectProperties })
    }
    else {
      return await WbArticleImage.loadArticleImage(article, 500, 500, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle, noImageObjectProperties }).then((newImg) => {
        newImg.setProp('scale', scaleValue)
        return newImg
      })
    }
  }
}

export default new LevisMensBottomsRTFinish()
