<template>
  <div ref="refFilterCriteria">
    <div class="flex content-center mb-1">
      <label class="w-full text-xs tracking-wide uppercase" v-text="t('general.criteria')" />
      <tx-switch v-model="localModelValue.exclude" v-tooltip="t('general.exclude')" class="w-auto h-5" :disabled="disabledExclude" />
    </div>
    <tx-select
      :ref="refSelect" v-model="localModelValue.attribute" class="mb-6" filterable clearable :placeholder="t('filter.criteriaPlaceholder')"
      :data="filterAttributes" value-prop="SystemName" display-prop="DisplayName" @change="onAttributeSelected"
    />
    <template v-if="selectedAttribute">
      <tx-range
        v-if="props.modelValue.mode === FilterCriteriaMode.numberRange" ref="refVal"
        v-model:from="localModelValue.numberVal" v-model:to="localModelValue.numberVal2"
        :step="selectedAttribute.AttributeType === AttributeType.Decimal ? '0.01' : undefined" class="mb-3" clearable
      />
      <tx-date-picker
        v-else-if="props.modelValue.mode === FilterCriteriaMode.dateRange" ref="refVal"
        v-model="localModelValue.multipleVals" range multi-calendars auto-apply
      />
      <tx-date-picker
        v-else-if="props.modelValue.mode === FilterCriteriaMode.dateTimeRange" ref="refVal"
        v-model="localModelValue.multipleVals" :enable-time-picker="true" range multi-calendars auto-apply
      />
      <tx-select
        v-else-if="props.modelValue.mode === FilterCriteriaMode.status" ref="refVal" v-model="localModelValue.statusVal"
        :data="selectedAttributeLookupData" value-prop="key" display-prop="label" filterable clearable :sort-list="false"
      />
      <tx-select
        v-else-if="selectedAttribute.FilterLookup.size > 0" ref="refVal" v-model="localModelValue.multipleVals" filterable multiple-values clearable
        :data="selectedAttributeLookupData" value-prop="key" display-prop="label" :sort-list="selectedAttribute.sortList"
      />
      <tx-input
        v-else-if="props.modelValue.mode === FilterCriteriaMode.multiString" ref="refVal" v-model.trim="localModelValue.stringVal"
        type="textarea" :label="t('general.value')" class="mb-3" clearable
      />
      <tx-input v-else ref="refVal" v-model="localModelValue.stringVal" type="text" :label="t('general.value')" class="mb-3" clearable />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxRange from '@/shared/components/TxRange.vue'
import TxDatePicker from '@/shared/components/TxDatePicker.vue'
import type { FilterCriteria } from '@/models/filterCriteria'
import { FilterCriteriaMode } from '@/models/filterCriteria'
import { AttributeType } from '@/models/catalogAttribute'
import useFilterableAttributes from '@/shared/composables/filterableAttributes'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'

interface IProps {
  modelValue: FilterCriteria
  exclude?: string[]
  focus?: boolean
}
const props = withDefaults(defineProps<IProps>(), { exclude: () => [] as string[], focus: false })

const emits = defineEmits<{
  (e: 'update:modelValue', val: FilterCriteria): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { filterableAttributes } = useFilterableAttributes()

const refFilterCriteria = ref<HTMLElement>()
const refSelect = ref<any>()
const refVal = ref<any>()

const localModelValue = computed({
  get: () => props.modelValue,
  set: modelValue => emits('update:modelValue', modelValue),
})

const filterAttributes = computed(() => {
  const excludeSet = new Set(props.exclude)
  return filterableAttributes.value
    .filter(itm => itm.SystemName === props.modelValue.attribute || !excludeSet.has(itm.SystemName))
})

const selectedAttribute = computed(() => (utils.isDefined(props.modelValue) && utils.isDefined(userStore.myAttributes))
  ? userStore.myAttributes[props.modelValue.attribute]
  : undefined)

const disabledExclude = computed(() => selectedAttribute.value && selectedAttribute.value.SystemName === '_Seasons')

const selectedAttributeLookupData = computed(() => selectedAttribute.value
  ? Array.from(selectedAttribute.value.FilterLookup, (item) => { return { key: item[0], label: item[1] } })
  : [])

async function onAttributeSelected() {
  props.modelValue.resetValues()
  setMode()
  await nextTick()
  if (refVal.value) {
    refVal.value.focus()
  }
}

function handlePaste(event) {
  // Prevent event propagation
  event.stopPropagation()
}

onMounted(async () => {
  await nextTick()
  if (refSelect.value) {
    refSelect.value.focus()
  }
  if (props.focus) {
    nextTick(() => {
      refFilterCriteria.value?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      refVal.value?.focus()
    })
  }
  if (refVal.value && refVal.value.el) {
    refVal.value.el.addEventListener('paste', handlePaste)
  }
})

onUnmounted(() => {
  if (refVal.value && refVal.value.el) {
    refVal.value.el.removeEventListener('paste', handlePaste)
  }
})

function setMode() {
  localModelValue.value.mode = FilterCriteriaMode.string
  if (utils.isDefined(selectedAttribute.value)) {
    if (selectedAttribute.value.SystemName === 'Status') {
      localModelValue.value.mode = FilterCriteriaMode.status
    }
    else if ((selectedAttribute.value.SystemName === 'ArticleNumber'
      || selectedAttribute.value.SystemName === 'ModelNumber'
      || selectedAttribute.value.SystemName === '_RequestNumber'
      || selectedAttribute.value.SystemName === 'ParentModelNumber'
      || selectedAttribute.value.SystemName === '_SourceModelNumber'
      || selectedAttribute.value.FilterLookup.size > 0)) {
      localModelValue.value.mode = FilterCriteriaMode.multiString
    }
    else if (selectedAttribute.value.AttributeType === AttributeType.Int || selectedAttribute.value.AttributeType === AttributeType.Decimal) {
      localModelValue.value.mode = FilterCriteriaMode.numberRange
    }
    else if (selectedAttribute.value.AttributeType === AttributeType.Date) {
      localModelValue.value.mode = FilterCriteriaMode.dateRange
    }
    else if (selectedAttribute.value.AttributeType === AttributeType.DateTime) {
      localModelValue.value.mode = FilterCriteriaMode.dateTimeRange
    }
  }
}

watch(() => localModelValue.value.stringVal, (value) => {
  if (localModelValue.value.mode === FilterCriteriaMode.multiString) {
    if (value?.trim()) {
      localModelValue.value.multipleVals = value.split('\n')
    }
    else {
      // Prevent empty strings from being assigned when cleared
      localModelValue.value.multipleVals = []
    }
  }
})
</script>
