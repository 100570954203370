import type { fabric } from 'fabric'
import { isArray, orderBy, sortBy } from 'lodash-es'
import WbArticleImage from '../../articleImage'
import WbArticleDetails from '../../articleDetails'
import WbTextBox from '../../textBox'
import WbShape from '../../shape'
import type { DynamicTempalateOptionValues, DynamicTemplateOptionValue, IDynamicTemplate } from './dynamicGridTemplateFactory'
import type MyArticle from '@/models/myArticle'

const op = {
  title: {
    name: 'Title',
    type: 'text',
    min: 1,
    max: 500,
    required: true,
    default: null,
  },
  rowAttribute: {
    name: 'Row Attribute',
    type: 'attributes',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  colAttribute: {
    name: 'Column Attribute',
    type: 'attributes',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  sortBy: {
    name: 'Sort By',
    type: 'attributes',
    min: 1,
    max: 5,
    required: true,
    default: null,
  },
  sortDescending: {
    name: 'Sort Descending',
    type: 'boolean',
    min: 1,
    max: 1,
    required: true,
    default: false,
  },
  imageSize: {
    name: 'Image Size',
    type: 'number',
    min: 50,
    max: 200,
    required: true,
    default: 110,
  },
  groupTitlePrefix: {
    name: 'Group Title Prefix',
    type: 'text',
    min: 1,
    max: 500,
    required: false,
    default: '',
  },
  mainDetailsAttributesBelow: {
    name: 'Main Detail Attributes Below Image',
    type: 'attributes',
    min: 1,
    max: 12,
    required: true,
    default: null,
  },
  mainDetailsAttributesInside: {
    name: 'Main Detail Attributes Inside Image',
    type: 'attributes',
    min: 1,
    max: 12,
    required: true,
    default: null,
  },
  variantState: {
    name: 'Variant State',
    type: 'state',
    min: 1,
    max: 1,
    required: false,
    default: null,
  },
  variantDetailsAttributesBelow: {
    name: 'Variant Detail Attributes Below Image',
    type: 'attributes',
    min: 1,
    max: 12,
    required: false,
    default: null,
  },
  variantDetailsAttributesInside: {
    name: 'Variant Detail Attributes Inside Image',
    type: 'attributes',
    min: 1,
    max: 12,
    required: false,
    default: null,
  },
  noImageAttributesAppliesToState: {
    name: 'No Image Attributes Applies To State (blank = all)',
    type: 'state',
    min: 1,
    max: 1,
    required: false,
    default: null,
  },
  noImageBackgroundColorAttribute: {
    name: 'No Image Background Color Attribute',
    type: 'attributes',
    min: 1,
    max: 1,
    required: false,
    default: null,
  },
  noImageBackgroundColorValue1: {
    name: 'No Image Background Color Value 1',
    type: 'text',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColor1: {
    name: 'No Image Background Color 1',
    type: 'color',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColorValue2: {
    name: 'No Image Background Color Value 2',
    type: 'text',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColor2: {
    name: 'No Image Background Color 2',
    type: 'color',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColorValue3: {
    name: 'No Image Background Color Value 3',
    type: 'text',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColor3: {
    name: 'No Image Background Color 3',
    type: 'color',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColorValue4: {
    name: 'No Image Background Color Value 4',
    type: 'text',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageBackgroundColor4: {
    name: 'No Image Background Color 4',
    type: 'color',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  noImageOutlineColorAttribute: {
    name: 'No Image Outline Color Attribute',
    type: 'attributes',
    min: 1,
    max: 1,
    required: false,
    default: null,
  },
  noImageOutlineColorValue: {
    name: 'No Image Outline Color Value (* = any)',
    type: 'text',
    min: 1,
    max: 500,
    required: false,
    default: null,
  },
  noImageOutlineColor: {
    name: 'No Image Outline Color',
    type: 'color',
    min: 1,
    max: 1,
    required: false,
    default: null,
  },
  axisLabelFontSize: {
    name: 'Axis Label Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 40,
  },
  groupFontSize: {
    name: 'Group Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 24,
  },
  articleDetailsFontSize: {
    name: 'Article Details Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 8,
  },
  spacing: {
    name: 'Spacing',
    type: 'text',
    min: 0,
    max: 500,
    required: true,
    default: 'Comfortable',
    list: ['Comfortable', 'Compact'],
  },
} as const

interface IArticleEntry {
  articleId: number
  imgObj: WbArticleImage
  detailsObj: WbArticleDetails
  stillVisible: boolean
}

interface IRowEntry {
  row: string
  obj: WbTextBox
  stillVisible: boolean
}

interface IColumnEntry {
  column: string
  obj: WbTextBox
  width: number
  stillVisible: boolean
}

interface IValueEntry {
  value: string
  boxObj: WbShape
  titleObj: WbTextBox
  stillVisible: boolean
  articleEntriesKeys: string[]
}

export class PivotViewTemplate implements IDynamicTemplate {
  id = 'pivotView'
  name = 'Pivot View'
  options = op

  private spacingLayout: { [layout: string]: { mainMargin: number, yAxisLabelWidth: number, yAxisLabelPadding: number, valueBoxPadding: number, xAxisLabelPadding: number, xAxisLabelMinWidth: number, xAxisLabelHeight: number, valueTitleHeight: number, imageBottomSpacing: number, articleSpaceBetween: number, rowSpaceBetween: number } }
    = {
      Comfortable: { mainMargin: 10, yAxisLabelWidth: 150, yAxisLabelPadding: 20, valueBoxPadding: 20, xAxisLabelPadding: 20, xAxisLabelMinWidth: 100, xAxisLabelHeight: 50, valueTitleHeight: 50, imageBottomSpacing: 10, articleSpaceBetween: 10, rowSpaceBetween: 20 },
      Compact: { mainMargin: 5, yAxisLabelWidth: 100, yAxisLabelPadding: 10, valueBoxPadding: 10, xAxisLabelPadding: 10, xAxisLabelMinWidth: 75, xAxisLabelHeight: 40, valueTitleHeight: 40, imageBottomSpacing: 5, articleSpaceBetween: 5, rowSpaceBetween: 10 },
    }

  private articles: MyArticle[] = []
  private opt?: DynamicTempalateOptionValues<typeof op>
  public minRequiredWidth: number = 0
  public minRequiredHeight: number = 0
  private colObjs: { [label: string]: IColumnEntry } = {}
  private rowObjs: { [label: string]: IRowEntry } = {}
  private valueObjs: { [colLabel: string]: { [rowLabel: string]: IValueEntry } } = {}
  private articleEntries: { [articleIdIteration: string]: IArticleEntry } = {}

  build(canvas: fabric.Canvas, myAttributes: Record<string, IMyAttribute>, options: { [config: string]: DynamicTemplateOptionValue }, showLabels: boolean, width: number, height: number, top, left, articles: MyArticle[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const articleDetailsPromises: Promise<WbArticleDetails>[] = []
      const articleImagesPromises: Promise<WbArticleImage>[] = []

      this.opt = options as DynamicTempalateOptionValues<typeof op>
      this.articles = orderBy(articles, this.opt!.sortBy as string[], this.opt!.sortDescending ? ['desc'] : ['asc'])

      // Reset all objects visibility
      Object.values(this.colObjs).forEach((col) => { col.stillVisible = false })
      Object.values(this.rowObjs).forEach((row) => { row.stillVisible = false })
      Object.values(this.valueObjs).forEach(col => Object.values(col).forEach((row) => { row.stillVisible = false }))
      Object.values(this.articleEntries).forEach((entry) => { entry.stillVisible = false })

      // Create the objects structure
      this.articles.forEach((article) => {
        const colsVal = article[this.opt!.colAttribute![0]] && isArray(article[this.opt!.colAttribute![0]]) ? article[this.opt!.colAttribute![0]] as string[] : [article[this.opt!.colAttribute![0]] as string || '[Blank]']
        const rowsVal = article[this.opt!.rowAttribute![0]] && isArray(article[this.opt!.rowAttribute![0]]) ? article[this.opt!.rowAttribute![0]] as string[] : [article[this.opt!.rowAttribute![0]] as string || '[Blank]']

        colsVal.forEach((colVal, colIndex) => {
        // Check if column object exists
          if (!this.colObjs[colVal.toLowerCase()]) {
            this.colObjs[colVal.toLowerCase()] = { column: colVal, width: 0, obj: new WbTextBox(colVal, { fontSize: this.opt?.axisLabelFontSize as number || 20, fontFamily: 'Roboto', fontWeight: 'bold', visible: false, lock: true, preventUnlock: true, localOnly: true, excludeFromGroupSelection: true, preventDelete: true }), stillVisible: false }
            canvas.add(this.colObjs[colVal.toLowerCase()].obj)
          }
          this.colObjs[colVal.toLowerCase()].stillVisible = true

          rowsVal.forEach((rowVal, rowIndex) => {
            // Check if row object exists
            if (!this.rowObjs[rowVal.toLowerCase()]) {
              this.rowObjs[rowVal.toLowerCase()] = { row: rowVal, obj: new WbTextBox(rowVal, { fontSize: this.opt?.axisLabelFontSize as number || 20, fontFamily: 'Roboto', fontWeight: 'bold', visible: false, lock: true, preventUnlock: true, localOnly: true, excludeFromGroupSelection: true, preventDelete: true }), stillVisible: false }
              canvas.add(this.rowObjs[rowVal.toLowerCase()].obj)
            }
            this.rowObjs[rowVal.toLowerCase()].stillVisible = true

            // Check if articleEntry exists
            const articleEntryKey = `${article.Id}-${colIndex * rowIndex}`

            const detailsAttributesBelow = article.StateId === options.variantState as number ? this.opt?.variantDetailsAttributesBelow as string[] || [] : this.opt?.mainDetailsAttributesBelow as string[] || []
            const detailsAttributesInside = article.StateId === options.variantState as number ? this.opt?.variantDetailsAttributesInside as string[] || [] : this.opt?.mainDetailsAttributesInside as string[] || []

            let noImageAttributes: { backgroundColor?: string, borderColor?: string } = {}
            if (!this.opt?.noImageAttributesAppliesToState || this.opt?.noImageAttributesAppliesToState === article.StateId) {
              if (this.opt?.noImageBackgroundColorAttribute && isArray(this.opt?.noImageBackgroundColorAttribute) && this.opt?.noImageBackgroundColorAttribute.length > 0) {
                const attVal = article[this.opt.noImageBackgroundColorAttribute[0]]?.toString() || ''
                switch (attVal.toLowerCase()) {
                  case this.opt.noImageBackgroundColorValue1?.toString().toLowerCase():
                    noImageAttributes = { backgroundColor: this.opt.noImageBackgroundColor1 as string }
                    break
                  case this.opt.noImageBackgroundColorValue2?.toString().toLowerCase():
                    noImageAttributes = { backgroundColor: this.opt.noImageBackgroundColor2 as string }
                    break
                  case this.opt.noImageBackgroundColorValue3?.toString().toLowerCase():
                    noImageAttributes = { backgroundColor: this.opt.noImageBackgroundColor3 as string }
                    break
                  case this.opt.noImageBackgroundColorValue4?.toString().toLowerCase():
                    noImageAttributes = { backgroundColor: this.opt.noImageBackgroundColor4 as string }
                    break
                  default:
                    break
                }
              }

              noImageAttributes.borderColor = '#808080'
              if (this.opt?.noImageOutlineColorAttribute && isArray(this.opt?.noImageOutlineColorAttribute) && this.opt?.noImageOutlineColorAttribute.length > 0) {
                const attVal = article[this.opt.noImageOutlineColorAttribute[0]]?.toString() || ''
                if (attVal.toLowerCase() === this.opt.noImageOutlineColorValue?.toString().toLowerCase() || (this.opt.noImageOutlineColorValue?.toString() === '*' && attVal !== '')) {
                  noImageAttributes.borderColor = this.opt.noImageOutlineColor as string
                }
              }
            }

            if (!this.articleEntries[articleEntryKey]) {
              const newArticleEntry = { articleId: article.Id, imgObj: {} as WbArticleImage, detailsObj: {} as WbArticleDetails, stillVisible: false }
              this.articleEntries[articleEntryKey] = newArticleEntry
              articleDetailsPromises.push(
                WbArticleDetails.loadArticleDetails(
                  article,
                  myAttributes,
                  { left: 0, top: 0, width: options.imageSize as number, showLabels, visible: true, lock: true, preventUnlock: true, localOnly: true, attributes: detailsAttributesBelow, fontSize: this.opt?.articleDetailsFontSize as number || 8, fontFamily: 'Roboto', fill: '#000000', excludeFromGroupSelection: true, preventDelete: true },
                ).then((details) => {
                  newArticleEntry.detailsObj = details
                  return details
                }),
              )
              articleImagesPromises.push(
                WbArticleImage.loadArticleImage(
                  article,
                  500,
                  500,
                  { top: 0, left: 0, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle, visible: true, lock: true, preventUnlock: true, localOnly: true, preventDelete: true, noImageObjectProperties: { border: { borderColor: noImageAttributes.borderColor, strokeWidth: 1 }, backgroundColor: noImageAttributes.backgroundColor || '#e6e6e6', attributes: detailsAttributesInside } },
                ).then((img) => {
                  newArticleEntry.imgObj = img
                  return img
                }),

              )
            }
            else {
              if (this.articleEntries[articleEntryKey].detailsObj.showLabels !== showLabels) {
                this.articleEntries[articleEntryKey].detailsObj.setProp('showLabels', { showLabels })
              }
            }
            this.articleEntries[articleEntryKey].stillVisible = true

            // Check if value object exists
            if (!this.valueObjs[colVal.toLowerCase()]) {
              this.valueObjs[colVal.toLowerCase()] = {}
            }
            if (!this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()]) {
              this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()] = {
                value: '',
                boxObj: new WbShape('rectangle', { fill: 'white', stroke: '#dadada', strokeWidth: 1, visible: false, lock: true, preventUnlock: true, localOnly: true, excludeFromGroupSelection: true, preventDelete: true }),
                titleObj: new WbTextBox(`${this.opt?.groupTitlePrefix || ''}${rowVal} - ${colVal}`, { fontSize: this.opt?.groupFontSize as number || 20, fontFamily: 'Roboto', visible: false, lock: true, preventUnlock: true, localOnly: true, excludeFromGroupSelection: true, preventDelete: true }),
                stillVisible: false,
                articleEntriesKeys: [],
              }
              canvas.add(this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()].boxObj)
              canvas.add(this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()].titleObj)
            }
            this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()].stillVisible = true
            if (!this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()].articleEntriesKeys.includes(articleEntryKey)) {
              this.valueObjs[colVal.toLowerCase()][rowVal.toLowerCase()].articleEntriesKeys.push(articleEntryKey)
            }
          })
        })
      })

      // Remove objects that are no longer needed
      Object.keys(this.colObjs).forEach((col) => {
        if (!this.colObjs[col].stillVisible) {
          canvas.remove(this.colObjs[col].obj)
          delete this.colObjs[col]
        }
      })
      Object.keys(this.rowObjs).forEach((row) => {
        if (!this.rowObjs[row].stillVisible) {
          canvas.remove(this.rowObjs[row].obj)
          delete this.rowObjs[row]
        }
      })
      Object.keys(this.valueObjs).forEach((col) => {
        Object.keys(this.valueObjs[col]).forEach((row) => {
          if (!this.valueObjs[col][row].stillVisible) {
            canvas.remove(this.valueObjs[col][row].boxObj)
            canvas.remove(this.valueObjs[col][row].titleObj)
            delete this.valueObjs[col][row]
          }
        })
      })
      Object.keys(this.articleEntries).forEach((articleEntryKey) => {
        if (!this.articleEntries[articleEntryKey].stillVisible) {
          canvas.remove(this.articleEntries[articleEntryKey].imgObj)
          canvas.remove(this.articleEntries[articleEntryKey].detailsObj)
          delete this.articleEntries[articleEntryKey]
        }
      })

      // Add the new article/ objects to canvas
      Promise.all(articleDetailsPromises).then((articleDetails) => {
        articleDetails.forEach((details) => {
          details.visible = false
          canvas.add(details)
        })

        Promise.all(articleImagesPromises).then((articleImages) => {
          articleImages.forEach((img) => {
            img.scale(options.imageSize as number * 0.002)
            img.visible = false
            canvas.add(img)
          })
          const res = this.recalculate(width, height, top, left)
          resolve(res)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /**
   * Recalculate the layout of the objects
   * @param width The width available for the layout
   * @param height The height available for the layout
   * @param startTop The top position to start the layout
   * @param startLeft The left position to start the layout
   * @returns True if the layout fits in the available space, false otherwise
   */
  recalculate(width: number, height: number, startTop: number, startLeft: number) {
    const layout = this.spacingLayout[this.opt?.spacing as string || 'Comfortable']
    let maxDetailsLabelHeight = 0
    const imgSize = (this.opt?.imageSize as number) || 150
    const sortedRowLabels = Object.keys(this.rowObjs).sort()
    const sortedColLabels = Object.keys(this.colObjs).sort()

    // Calculate the required width based on the max number of articles in a row
    Object.values(this.colObjs).forEach((col) => {
      // Find the row with the most articles
      col.width = 0
      Object.values(this.rowObjs).forEach((row) => {
        const value = this.valueObjs[col.column.toLowerCase()][row.row.toLowerCase()]
        if (value) {
          col.width = Math.max(col.width, value.articleEntriesKeys.length * imgSize + (value.articleEntriesKeys.length - 1) * layout.articleSpaceBetween + layout.valueBoxPadding * 2 + layout.xAxisLabelPadding)

          value.articleEntriesKeys.forEach((articleEntryKey) => {
            const articleEntry = this.articleEntries[articleEntryKey]
            articleEntry.detailsObj.set({ width: imgSize })
            maxDetailsLabelHeight = Math.max(maxDetailsLabelHeight, articleEntry.detailsObj.height || 0)
          })
        }
      })
    })

    // Calculate the required width
    this.minRequiredWidth = layout.mainMargin * 2 + layout.yAxisLabelWidth + layout.yAxisLabelPadding + Object.values(this.colObjs).reduce((acc, col) => acc + col.width, 0) + (Object.values(this.colObjs).length - 1) * layout.xAxisLabelPadding

    // Calculate the required height
    this.minRequiredHeight = layout.mainMargin * 2 + layout.xAxisLabelHeight + layout.xAxisLabelPadding + Object.values(this.rowObjs).length * (layout.valueBoxPadding * 2 + layout.valueTitleHeight + imgSize + layout.imageBottomSpacing + maxDetailsLabelHeight + layout.rowSpaceBetween)

    if (width < this.minRequiredWidth || height < this.minRequiredHeight) {
      return false
    }

    // Render objects
    const pos = { left: startLeft, top: startTop }
    sortedRowLabels.forEach((rowLabel) => {
      const row = this.rowObjs[rowLabel]

      pos.left = startLeft + layout.mainMargin
      row.obj.set({ left: pos.left, top: pos.top + layout.xAxisLabelHeight + layout.xAxisLabelPadding, width: layout.yAxisLabelWidth, visible: true })
      pos.left += layout.yAxisLabelWidth + layout.yAxisLabelPadding

      sortedColLabels.forEach((colLabel) => {
        const col = this.colObjs[colLabel]
        const value = this.valueObjs[colLabel][rowLabel]

        // Position the column label
        col.obj.set({ left: pos.left, top: startTop + layout.mainMargin, width: col.width, visible: true })

        if (value) {
          // Position the value box
          const boxWidth = value.articleEntriesKeys.length * imgSize + (value.articleEntriesKeys.length - 1) * layout.articleSpaceBetween + layout.valueBoxPadding * 2
          value.boxObj.set({ left: col.obj.left, top: row.obj.top, scaleX: boxWidth / value.boxObj.width!, scaleY: (layout.valueBoxPadding * 2 + layout.valueTitleHeight + imgSize + layout.imageBottomSpacing + maxDetailsLabelHeight) / value.boxObj.height!, visible: true })

          // Position the value title
          value.titleObj.set({ left: value.boxObj.left! + layout.valueBoxPadding, top: value.boxObj.top! + layout.valueBoxPadding, width: boxWidth - layout.valueBoxPadding * 2, visible: true })

          // Position the articles
          value.articleEntriesKeys.forEach((articleEntryKey, index) => {
            const articleEntry = this.articleEntries[articleEntryKey]
            const articleXOffset = index * (imgSize + layout.articleSpaceBetween)
            articleEntry.imgObj.set({ left: value.boxObj.left! + layout.valueBoxPadding + articleXOffset, top: value.boxObj.top! + layout.valueBoxPadding + layout.valueTitleHeight, visible: true })
            articleEntry.detailsObj.set({ left: articleEntry.imgObj.left, top: articleEntry.imgObj.top! + imgSize + layout.imageBottomSpacing, visible: true })
          })
        }
        pos.left += col.width + layout.xAxisLabelPadding
      })
      pos.top += layout.valueBoxPadding * 2 + layout.valueTitleHeight + imgSize + layout.imageBottomSpacing + maxDetailsLabelHeight + layout.rowSpaceBetween
    })

    return true
  }

  propagateEvent(eventName: string, event: any) {
    Object.values(this.colObjs).forEach((col) => {
      col.obj.fire(eventName, event)
    })
    Object.values(this.rowObjs).forEach((row) => {
      row.obj.fire(eventName, event)
    })
    Object.values(this.valueObjs).forEach((col) => {
      Object.values(col).forEach((row) => {
        row.boxObj.fire(eventName, event)
        row.titleObj.fire(eventName, event)
        row.articleEntriesKeys.forEach((articleEntryKey) => {
          this.articleEntries[articleEntryKey].imgObj.fire(eventName, event)
          this.articleEntries[articleEntryKey].detailsObj.fire(eventName, event)
        })
      })
    })
  }

  hideObjects(hidden: boolean) {
    Object.values(this.colObjs).forEach((col) => {
      col.obj.visible = !hidden
    })
    Object.values(this.rowObjs).forEach((row) => {
      row.obj.visible = !hidden
    })
    Object.values(this.valueObjs).forEach((col) => {
      Object.values(col).forEach((row) => {
        row.boxObj.visible = !hidden
        row.titleObj.visible = !hidden
        row.articleEntriesKeys.forEach((articleEntryKey) => {
          this.articleEntries[articleEntryKey].imgObj.visible = !hidden
          this.articleEntries[articleEntryKey].detailsObj.visible = !hidden
        })
      })
    })
  }

  destroy(canvas: fabric.Canvas) {
    Object.values(this.colObjs).forEach((col) => {
      canvas.remove(col.obj)
    })
    Object.values(this.rowObjs).forEach((row) => {
      canvas.remove(row.obj)
    })
    Object.values(this.valueObjs).forEach((col) => {
      Object.values(col).forEach((row) => {
        canvas.remove(row.boxObj)
        canvas.remove(row.titleObj)
        row.articleEntriesKeys.forEach((articleEntryKey) => {
          const articleEntry = this.articleEntries[articleEntryKey]
          canvas.remove(articleEntry.imgObj)
          canvas.remove(articleEntry.detailsObj)
        })
      })
    })
  }

  render(ctx: CanvasRenderingContext2D, width: number, height: number) {
    // Draw a white rectangle with grey border for each main group
    ctx.fillStyle = '#f2f2f2'
    ctx.strokeStyle = '#808080'
    ctx.lineWidth = 1
    ctx.fillRect(-width / 2, -height / 2, width, height)
    ctx.strokeRect(-width / 2, -height / 2, width, height)
  }

  getTitle() {
    return this.opt?.title as string || '[Pivot View]'
  }
}
