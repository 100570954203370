import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { pick } from 'lodash-es'
import MyArticle from '@/models/myArticle'
import type Article from '@/models/article'
import type DuneAsset from '@/models/duneAsset'
import pngLoading from '@/assets/loading.png'
import pngNoImage from '@/assets/noimg.png'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import utils, { CancelToken } from '@/services/utils'
import { appConstants, privileges, whiteboardConstants } from '@/models/constants'
import { getArticleAssets } from '@/api/t1/article'

interface IWbArticleImageOptions extends fabric.IImageOptions, IWbObjectProps {
  id?: string
  catalogCode: number
  articleId: number
  objectId: string
  isRequest?: boolean
  assetKey?: string
  lock?: boolean
  preventUnlock?: boolean
  requestWaterMarkDetails?: Record<string, any>
  colorCode?: string
  status?: boolean
  localOnly?: boolean
  attributes?: string[]
  attributesSeparator?: string
  attributePlaceHolder?: string
  noImageObjectProperties?: Record<string, any>
  article?: MyArticle | Article
  showResources?: boolean
  resourceBoxLeft?: number
  resourceBoxTop?: number
  preventDelete?: boolean
  excludeFromGroupSelection?: boolean
  favColorList?: string[]
}

export default class WbArticleImage extends fabric.Image implements IWbObject {
  public id: string
  public catalogCode: number
  public articleId: number
  public objectId: string
  public isRequest: boolean
  public requestWaterMarkDetails: Record<string, any> = {}
  public colorCode = ''
  public status?: boolean
  public articleNumber?: string
  public assetKey?: string
  public assets: DuneAsset[]
  public type = whiteboardConstants.objectTypes.articleImage
  public selected = false
  public highlighted = false
  public lock: boolean
  public connectable: boolean
  public imgLoaded: boolean
  public imgLoading: boolean
  public localOnly: boolean
  public preventUnlock: boolean
  private isLoadingFirstTime = true
  public article?: MyArticle
  public attributes?: string[]
  public attributesSeparator?: string
  public attributePlaceHolder?: string
  public cachedAttributeThumbnailValue: string | null = null
  public noImageObjectProperties: { backgroundColor?: string, border?: { borderColor: string, strokeWidth: number }, attributes?: string[] } = {}
  public showResources: boolean
  private resourceBoxRect: fabric.Rect
  private resourceBoxText: fabric.Text
  private resourceBoxImages: fabric.Image[]
  public resourceBoxLeft: number
  public resourceBoxTop: number
  public preventDelete?: boolean
  private isResourcesBoxMoving = false
  private isMoving = false
  private lastMoveLeft = 0
  private lastMoveTop = 0
  private displayedImg: 'loading' | 'noImage' | 'image' | 'articleDetails' | 'none' = 'none'
  public excludeFromGroupSelection?: boolean
  public favColorList: string[] = []

  public editableProps: Record<string, IWbObjectProp> = {
    imageType: { name: 'Image Type', type: 'imageType', editable: true },
    scale: { name: 'Size', type: 'scale', editable: true },
    lock: { name: 'Lock', type: 'lock', editable: true, editableWhenLocked: true },
    addArticleDetails: { name: 'Add Article Details', type: 'addArticleDetails', editable: true },
    addDiscussion: { name: 'Add Comment', type: 'addDiscussion', editable: true, editableWhenLocked: true },
    assignImage: { name: 'Assign Image', type: 'assignImage', editable: true, editableWhenLocked: true },
    showResources: { name: 'Show resources', type: 'showResources', editable: true, editableWhenLocked: true },
    addThumbnailAttributes: { name: 'Add Thumbnail Attributes', type: 'addThumbnailAttributes', editable: false },
    backgroundColor: { name: 'Background Color', type: 'backgroundColor', editable: false },
    border: { name: 'Border', type: 'border', editable: false },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copyModel: {
      action: 'copyModel',
      label: 'Copy Model',
      faicon: 'fa-light fa-copy',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && !userStore.currentCustomer
          && userStore.userProfile.isValidPrivilege(privileges.article.carryOverArticles)
          && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog?.AccountId)
      })(),
    },
    copyArticles: {
      action: 'copyArticles',
      label: 'Copy Articles',
      faicon: 'fa-light fa-copy',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return this.catalogCode === userStore.activeCatalog?.CatalogCode
          && userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && !userStore.currentCustomer
          && userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticles)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog?.AccountId)
      })(),
    },
    editArticles: {
      action: 'editArticles',
      label: 'Edit Articles',
      faicon: 'fa-light fa-edit',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return this.catalogCode === userStore.activeCatalog?.CatalogCode && userStore.userProfile.isValidPrivilege(privileges.article.update)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog?.AccountId)
      })(),
    },
    cloneAsImage: { action: 'cloneAsImage', label: 'Clone As Image', faicon: 'fa-light fa-clone', showInSubMenu: true },
    saveImages: {
      action: 'saveImages',
      label: 'Save Images',
      faicon: 'fa-light fa-download',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.activeCatalog?.Config.AllowDownloadImages === true && !this.isRequest
      })(),
    },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
    editFavorites: { action: 'editFavorites', label: 'Edit Favorites', faicon: 'fa-light fa-star', showInSubMenu: true },
  }

  public subscriptions: Array<Subscription>

  constructor(element: string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, options: IWbArticleImageOptions, isLiveObject = true) {
    super(element, options)
    const userStore = useUserStore()
    this.id = options.id || guid()
    this.catalogCode = options.catalogCode
    this.articleId = options.articleId
    this.objectId = options.objectId
    this.isRequest = options.isRequest || false
    this.requestWaterMarkDetails = options.requestWaterMarkDetails || { fontColor: '#5093e16e', waterMarkText: 'Request' }
    this.colorCode = options.colorCode || ''
    this.status = options.status
    this.assetKey = options.assetKey
    this.lock = options.lock || false
    this.localOnly = options.localOnly || false
    this.connectable = true
    this.assets = []
    this.imgLoaded = false
    this.imgLoading = false
    this.subscriptions = []
    this.preventUnlock = options.preventUnlock || false
    this.attributesSeparator = options?.attributesSeparator || '\n'
    this.attributePlaceHolder = options?.attributePlaceHolder || ''
    this.showResources = Boolean(options?.showResources)
    this.resourceBoxLeft = options.resourceBoxLeft || (this.left || 0)
    this.resourceBoxTop = options.resourceBoxTop || (this.top || 0) - 10

    this.resourceBoxRect = new fabric.Rect({
      left: this.resourceBoxLeft,
      top: this.resourceBoxTop,
      width: 200,
      height: 130,
      fill: 'rgba(200, 200, 200, 0.6)',
      rx: 8,
      ry: 8,
      stroke: '#000000',
      strokeWidth: 1,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
    })
    this.resourceBoxText = new fabric.Text(this.articleNumber || '', {
      left: this.resourceBoxLeft + 5,
      top: this.resourceBoxTop + 5,
      fontSize: 14,
      fill: '#000',
      fontWeight: 'bold',
      selectable: false,
      evented: false,
    })
    this.resourceBoxImages = []
    this.handleResourcesBoxVisibility(this.showResources)

    this.preventDelete = options.preventDelete || false
    this.excludeFromGroupSelection = options.excludeFromGroupSelection || false
    this.favColorList = options.favColorList || []

    if (options.noImageObjectProperties) {
      this.noImageObjectProperties = options.noImageObjectProperties
      this.applyNoImageProperties()
    }
    else {
      this.set('backgroundColor', '#ffffff')
      this.setProp('border', { borderColor: '#000000', strokeWidth: 1 })
      this.noImageObjectProperties.attributes = options?.attributes?.length ? options.attributes : userStore.activeCatalog?.Config.WhiteboardThumbnailAttributes.length ? userStore.activeCatalog?.Config.WhiteboardThumbnailAttributes : ['ArticleNumber', 'ModelName'] // default are ArticleNumber and ModelName // assign default
      this.set('attributes', this.noImageObjectProperties.attributes)
    }
    if (options.article && options.article instanceof MyArticle) {
      this.loadTextValue(options.article)
    }
    if (isLiveObject) {
      const catalogDetails = userStore.linkedCatalogDetails[this.catalogCode] || userStore.activeCatalog
      const resourceType = catalogDetails.Config.WhiteboardDefaultResourceType
      if (this.isRequest) {
        const observable = liveQuery(async () => await appConfig.DB!.requests.where({ CatalogCode: catalogDetails.CatalogCode, Id: this.articleId }).toArray())
        const subscription = observable.subscribe(async ([request]) => {
          const article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB, userStore.myAttributes, userStore.priceGroups)
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime) {
            this.articleNumber = article.ArticleNumber
            this.setProp('status', article.Status === 1)
            this.canvas?.requestRenderAll()
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
      else {
        // non segmented article will have _IsNonSegmented as true
        const articleIds = [this.articleId]
        const observable = liveQuery(async () => await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articleIds, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true, userStore.currentCustomer, userStore.currentCustomerSegmentations, true))
        const subscription = observable.subscribe(async ([article]) => {
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          // this.article = article
          if (!this.isLoadingFirstTime) {
            this.loadTextValue(article)
            this.articleNumber = article.ArticleNumber
            this.setProp('status', article.Status === 1)
            this.setProp('colorCode', '')
            if (userStore.activeCatalog?.Config.WhiteboardColorCodingAttribute && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute?.length !== 0 && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute[0].attribute) {
              for (const configValue of userStore.activeCatalog.Config.WhiteboardColorCodingAttribute) {
                if (utils.isValidStringValue(article[configValue.attribute]) && article[configValue.attribute]?.toString().trim().toLowerCase() === configValue.value.toString().toLowerCase()) {
                  this.setProp('colorCode', configValue.color)
                  break
                }
              }
            }
            this.canvas?.requestRenderAll()
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)

        // resources
        const resourcesSubscription = liveQuery(async () => await appConfig.DB!.resources.where('Articles').anyOf([this.articleId]).filter(r => r.CatalogCode === catalogDetails.CatalogCode).toArray())
          .subscribe((resources) => {
            if (this.resourceBoxImages.length > 0) {
              this.canvas?.remove(...this.resourceBoxImages)
              this.canvas?.requestRenderAll()
            }

            const validImageResources = resources.filter(r => r.ResourceType.toLowerCase().includes(resourceType.toLowerCase()))
            this.resourceBoxRect.set('width', validImageResources.length > 1 ? validImageResources.length * 100 : 200)

            if (validImageResources.length) {
              const imageWidth = 90
              const imageHeight = 90
              const imageLoadPromises = validImageResources.map((resource, index) => {
                return new Promise<boolean>((resolve) => {
                  fabric.util.loadImage(resource.ResourceUrl, (img) => {
                    const left = (this.resourceBoxRect.left || 0) + (index * imageWidth) + 10
                    const top = (this.resourceBoxRect.top || 0) + 30
                    const image = new fabric.Image(img, {
                      left,
                      top,
                      scaleX: imageWidth / img.width,
                      scaleY: imageHeight / img.height,
                      selectable: false,
                      visible: this.showResources,
                      evented: false,
                    })
                    this.resourceBoxImages.push(image)
                    resolve(true)
                  })
                })
              })
              Promise.all(imageLoadPromises)
                .then(() => {
                  this.canvas?.add(...this.resourceBoxImages)
                  this.canvas?.requestRenderAll()
                })
            }
          })

        this.subscriptions.push(resourcesSubscription)
        if (userStore.activeCatalog && ((!utils.isDefined(userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode]) || !utils.isDefined(userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode].showFavorites)) || userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode].showFavorites)) {
          const favoritesObservable = liveQuery(async () => await appConfig.DB!.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').equals([userStore.activeCatalog!.CatalogCode, userStore.currentUsername, 1]).toArray())
          const favSubscription = favoritesObservable.subscribe(async (favoritesResponse) => {
            this.favColorList = []
            if (favoritesResponse != null && favoritesResponse.length > 0) {
              this.favColorList = favoritesResponse.filter(fav => fav.Articles.includes(this.articleId)).map(itm => itm.Color)
            }
            this.canvas?.requestRenderAll()
          })
          this.subscriptions.push(favSubscription)
        }
      }
    }

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['showResources', 'lock'])

    this.on('added', () => {
      this.canvas?.add(this.resourceBoxRect, this.resourceBoxText)
    })

    this.on('selected', () => {
      this.selected = true
    })

    this.on('deselected', () => {
      this.selected = false
    })

    this.on('removed', () => {
      this.canvas?.remove(this.resourceBoxRect, this.resourceBoxText, ...this.resourceBoxImages)
      // console.log('unsubscribing from article image observables')
      this.subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      })
    })

    this.resetObjMoving()

    this.on('moving', () => {
      this.isMoving = true
      const left = (this.left || 0)
      const top = (this.top || 0)

      const leftDiff = left - this.lastMoveLeft
      const topDiff = top - this.lastMoveTop
      this.resourceBoxRect.left = (this.resourceBoxRect.left || 0) + leftDiff
      this.resourceBoxRect.top = (this.resourceBoxRect.top || 0) + topDiff
      this.resourceBoxRect.setCoords()
      this.moveResourcesBoxObjects(this.resourceBoxRect.left, this.resourceBoxRect.top)
      this.canvas?.fire('object:moving', { target: this.resourceBoxRect })

      this.lastMoveLeft = left
      this.lastMoveTop = top
    })

    this.on('mouseup', () => {
      if (this.isMoving) {
        this.resetObjMoving()
      }
    })

    this.resourceBoxRect.on('moving', () => {
      this.isResourcesBoxMoving = true
      this.moveResourcesBoxObjects(this.resourceBoxRect.left, this.resourceBoxRect.top)
    })

    this.resourceBoxRect.on('mouseup', () => {
      if (this.isResourcesBoxMoving) {
        this.isResourcesBoxMoving = false
        this.set('resourceBoxLeft', this.resourceBoxRect.left || (this.left || 0))
        this.set('resourceBoxTop', this.resourceBoxRect.top || (this.top || 0) - 10)
        this.dirty = true
        this.canvas?.requestRenderAll()
        this.canvas?.fire('object:modified', { target: this, subTargets: [this.resourceBoxRect] })
      }
    })
  }

  private moveResourcesBoxObjects(left?: number, top?: number) {
    this.resourceBoxText.set({ left: (left || 0) + 5, top: (top || 0) + 5 })

    this.resourceBoxImages.forEach((img, index) => {
      img.set({ left: (left || 0) + 10 + (index * 90), top: (top || 0) + 30 })
    })
    this.canvas?.requestRenderAll()
  }

  resetObjMoving() {
    this.lastMoveLeft = this.left || 0
    this.lastMoveTop = this.top || 0
    this.isMoving = false
  }

  private applyNoImageProperties() {
    if (!this.assetKey) {
      this.set('borderColor', this.noImageObjectProperties.border && this.noImageObjectProperties.border.borderColor ? this.noImageObjectProperties.border.borderColor : '#000000')
      this.set('borderScaleFactor', this.noImageObjectProperties.border && this.noImageObjectProperties.border.strokeWidth ? this.noImageObjectProperties.border.strokeWidth : 1)

      this.set('backgroundColor', this.noImageObjectProperties.backgroundColor ? this.noImageObjectProperties.backgroundColor : '#ffffff')
      if (this.noImageObjectProperties.attributes) {
        this.set('attributes', this.noImageObjectProperties.attributes)
      }
    }
  }

  private handleResourcesBoxVisibility(visible: boolean) {
    this.resourceBoxRect.visible = visible
    this.resourceBoxText.visible = visible
    this.resourceBoxImages.forEach((image) => {
      image.visible = visible
    })
  }

  async setProp(prop: string, value: any, ignoreHistory: boolean = false) {
    switch (prop) {
      case 'imageType':
        this.set('assetKey', value.assetKey)
        this.imgLoaded = false
        this.imgLoading = false
        break
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scale = value.scale / 100
          this.set('scaleX', scale)
          this.set('scaleY', scale)
        }
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'status':
        this.set('status', value)
        break
      case 'colorCode':
        this.set('colorCode', value)
        break
      case 'showResources':
        this.set('showResources', value.showResources)
        this.handleResourcesBoxVisibility(Boolean(value.showResources))
        break
      case 'attributes':
        this.noImageObjectProperties.attributes = value.attributes
        this.set('attributes', value.attributes)
        await this.loadTextValue()
        break
      case 'border':
        this.noImageObjectProperties.border = {
          borderColor: value.borderColor,
          strokeWidth: value.strokeWidth,
        }
        this.set('borderColor', value.borderColor)
        this.set('borderScaleFactor', value.strokeWidth)
        break
      case 'backgroundColor':
        this.noImageObjectProperties.backgroundColor = value.backgroundColor
        this.set('backgroundColor', value.backgroundColor)
        break

      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this, ignoreHistory })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'imageType':
        result.assetKey = this.assetKey
        break
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'status':
        result.status = this.status
        break
      case 'showResources':
        result.showResources = this.showResources
        break
      case 'attributes':
        result.attributes = this.assetKey ? [] : this.noImageObjectProperties.attributes
        break
      case 'backgroundColor':
        result.backgroundColor = this.assetKey ? 'transparent' : this.noImageObjectProperties.backgroundColor
        break
      case 'border':
        result.borderColor = this.assetKey ? this.borderColor : this.noImageObjectProperties.border?.borderColor
        result.strokeWidth = this.assetKey ? this.strokeWidth : this.noImageObjectProperties.border?.strokeWidth
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  highlight() {
    this.highlighted = true
    this.set('stroke', '#2196f3')
    this.set('strokeWidth', 4)
    this.set('strokeDashArray', [20])
    this.canvas?.requestRenderAll()
  }

  dim() {
    this.highlighted = false
    this.set('stroke', undefined)
    this.set('strokeWidth', 0)
    this.set('strokeDashArray', undefined)
    this.canvas?.requestRenderAll()
  }

  midIntersectsWithObject(other: fabric.Object, absolute?: boolean, calculate?: boolean) {
    const points1 = this.getCoords(absolute, calculate)
    const points2 = other.getCoords(absolute, calculate)

    const xDelta = (points1[1].x - points1[0].x) / 4
    const yDelta = (points1[2].y - points1[0].y) / 4
    const increaseInX = points1[0].x + xDelta
    const increaseInY = points1[0].y + yDelta

    for (const point of points1) {
      point.x = (point.x >= increaseInX) ? (point.x - xDelta) : (point.x + xDelta)
      point.y = (point.y >= increaseInY) ? (point.y - yDelta) : (point.y + yDelta)
    }

    const intersection = fabric.Intersection.intersectPolygonPolygon(points1, points2)

    return intersection.status === 'Intersection'
      || other.isContainedWithinObject(this, absolute, calculate)
      || this.isContainedWithinObject(other, absolute, calculate)
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'width',
      'height',
      'angle',
      'scaleX',
      'scaleY',
      'lock',
      'version',
      'preventUnlock',
      'backgroundColor',
      'borderColor',
      'catalogCode',
      'articleId',
      'objectId',
      'isRequest',
      'assetKey',
      'status',
      'localOnly',
      'stroke',
      'strokeWidth',
      'strokeDashArray',
      'noImageObjectProperties',
      'showResources',
      'resourceBoxLeft',
      'resourceBoxTop',
    ]
    return pick(this, propsToPluck)
  }

  static fromObject(object: WbArticleImage, callback?: Function) {
    // handle existing objects
    if (!object.catalogCode) { object.catalogCode = -1 }
    if (object.catalogCode && object.articleId) {
      this.loadArticleImageById(object.catalogCode, object.articleId, object.objectId, object.width || 500, object.height || 500, object).then((v) => { callback && callback(v) })
    }
  }

  static async getImageSrc(catalogCode: number, articleId: number, objectId: string, isRequest: boolean, width: number, height: number, assets?: DuneAsset[], assetKey?: string) {
    let src = pngNoImage
    const userStore = useUserStore()
    const catalogDetails = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
    if (userStore && catalogDetails && assets && assets.length > 0) {
      let asset = assets[0]
      if (utils.isValidStringValue(assetKey)) {
        const selectedAsset = assets.find(asset => asset.Key === assetKey)
        if (selectedAsset) {
          asset = selectedAsset
        }
      }
      src = `${appConfig.AssetsUrl}/assets/content/${asset.StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    else if (appConfig.DB && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
      let article: Article | undefined
      if (isRequest) {
        const request = await appConfig.DB.requests.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId, IsCreateArticleRequest: 1 })
        if (request?.Content) {
          article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB, userStore.myAttributes, userStore.priceGroups)
        }
      }
      else {
        article = await appConfig.DB.articles.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId })
      }
      if (article) {
        const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, article, userStore.indexedSilhouetteImages)
        if (utils.isValidStringValue(imageKey) && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey) {
          src = `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
        }
      }
    }
    return src
  }

  static loadArticleImage(article: MyArticle | Article, width: number, height: number, opt?: IWbArticleImageOptions, favColorList?: string[]) {
    return new Promise<WbArticleImage>((resolve) => {
      fabric.util.loadImage(pngLoading, (img) => {
        const userStore = useUserStore()
        let colorCode = ''
        if (userStore.activeCatalog?.Config.WhiteboardColorCodingAttribute && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute.length !== 0 && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute[0].attribute) {
          for (const configValue of userStore.activeCatalog.Config.WhiteboardColorCodingAttribute) {
            if (utils.isValidStringValue(article[configValue.attribute]) && article[configValue.attribute]?.toString().trim().toLowerCase() === configValue.value.toString().toLowerCase()) {
              colorCode = configValue.color
              break
            }
          }
        }

        const o: IWbArticleImageOptions = Object.assign((opt || { width, height }), {
          catalogCode: article.CatalogCode,
          articleId: article.Id,
          objectId: article.CatalogArticleId,
          isRequest: Boolean(article._IsRequestArticle),
          requestWaterMarkDetails: utils.getRequestWaterTextAndFontColor(article),
          colorCode,
          status: article.Status === 1,
          article,
          favColorList,
        })
        const obj = new WbArticleImage(img, o)
        obj.articleNumber = article.ArticleNumber
        obj.resourceBoxRect.left = obj.resourceBoxLeft
        obj.resourceBoxRect.top = obj.resourceBoxTop

        const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog
        obj.imgLoading = true
        getArticleAssets(catalogDetails.DuneContext, catalogDetails.ContextKey, article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList)
          .then((assets) => {
            obj.assets = assets
          })
          .finally(() => {
            obj.imgLoaded = false
            obj.imgLoading = false
            obj.opacity = article.Status === 1 && !article._IsNonSegmented ? 1 : 0.5
            obj.dirty = true
            obj.canvas?.requestRenderAll()
          })
        resolve(obj)
      })
    })
  }

  static async getJsonObject(article: MyArticle, width: number, height: number, scaleValue, opt?: IWbArticleImageOptions) {
    const userStore = useUserStore()
    const options = Object.assign((opt || { width, height, noImageObjectProperties: { backgroundColor: '#ffffff', border: { color: '#000000', strokeWidth: 1 }, attributes: userStore.activeCatalog?.Config.WhiteboardThumbnailAttributes.length ? userStore.activeCatalog?.Config.WhiteboardThumbnailAttributes : ['ArticleNumber', 'ModelName'] } }), { catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: Boolean(article._IsRequestArticle), status: article.Status === 1 })
    const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog

    const obj: any = new WbArticleImage('', options, false).toObject()
    if (scaleValue.scale <= 100 && width && height) {
      const scale = scaleValue.scale / 100
      obj.scaleX = scale
      obj.scaleY = scale
    }
    if (article._Assets && article._Assets.length > 0 && catalogDetails) {
      obj.src = `${appConfig.AssetsUrl}/assets/content/${article._Assets[0].StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    else if (userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
      if (options.noImageObjectProperties) {
        obj.text = await WbArticleImage.getText(article, options.noImageObjectProperties.attributes, '\n', '', userStore.myAttributes)
      }
      const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, article, userStore.indexedSilhouetteImages)
      if (utils.isValidStringValue(imageKey) && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey) {
        obj.src = `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
      }
    }

    obj.height = height
    obj.width = width
    return obj
  }

  static async loadArticleImageById(catalogCode: number, articleId: number, objectId: string, width: number, height: number, opt: IWbArticleImageOptions) {
    const userStore = useUserStore()
    if (userStore.activeCatalog && appConfig.DB) {
      const catalogDetails = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
      if (opt.isRequest) {
        const request = await appConfig.DB.requests.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId, IsCreateArticleRequest: 1 })
        if (request?.Content) {
          const article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB, userStore.myAttributes, userStore.priceGroups)
          return await this.loadArticleImage(article, width, height, opt)
        }
      }
      else {
        let favColorList: string[] = []
        await appConfig.DB!.getMyArticles(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false, userStore.currentCustomer, userStore.currentCustomerSegmentations, true)
        const res = await appConfig.DB!.getMyArticles(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false, userStore.currentCustomer, userStore.currentCustomerSegmentations, true)
        if (res && !(res instanceof CancelToken) && res.length > 0) {
          favColorList = res[0]._FavoriteTags.map(tag => tag.Color)
          return await this.loadArticleImage(res[0], width, height, opt, favColorList)
        }
        else if (Object.keys(userStore.linkedCatalogDetails).length > 0) {
          for (const linkedCatalogCode of Object.keys(userStore.linkedCatalogDetails)) {
            const article = await appConfig.DB.articles.get({ CatalogCode: linkedCatalogCode, Id: articleId })
            if (article) {
              return await this.loadArticleImage(article, width, height, opt)
            }
          }
        }
      }
      console.warn('Failed to load article image by Id')
    }
    console.warn('Unable to load Article Image by Id')
    return null
  }

  static async getText(article: MyArticle, attributes?: string[], attributesSeparator?: string, attributePlaceHolder?: string, myAttributes?: Record<string, IMyAttribute>) {
    const attributeData: any[] = []
    const userStore = useUserStore()
    if (attributes && attributes.length && utils.isDefined(myAttributes)) {
      for (const attributeSystemName of attributes) {
        if (utils.isDefined(myAttributes[attributeSystemName])) {
          let val = await utils.getAttributeTypeSpecificValue(myAttributes[attributeSystemName], article, appConfig.DB, userStore.activeCatalog, userStore.priceGroups, false, true)
          if (!utils.isValidStringValue(val)) {
            val = attributePlaceHolder
          }
          attributeData.push(val)
        }
      }
    }
    return attributeData.join(attributesSeparator)
  }

  async loadTextValue(article?: MyArticle) {
    if (!this.noImageObjectProperties?.attributes) { return }

    const userStore = useUserStore()
    let articleData = article
    if (!article) {
      articleData = (await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [this.articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true, userStore.currentCustomer, userStore.currentCustomerSegmentations, true))[0]
    }
    if (articleData) {
      this.cachedAttributeThumbnailValue = await WbArticleImage.getText(articleData, this.noImageObjectProperties.attributes, '\n', '', userStore.myAttributes)
    }
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
    const userStore = useUserStore()
    if (!this.imgLoaded && !this.imgLoading) {
      console.log('loading image')
      this.imgLoading = true
      WbArticleImage.getImageSrc(this.catalogCode, this.articleId, this.objectId, this.isRequest, 500, 500, this.assets, this.assetKey)
        .then((src) => {
          if (src !== pngNoImage) {
            this.setSrc(src, (img, error) => {
              if (error) {
                this.setSrc(pngNoImage, () => {
                  this.canvas?.requestRenderAll()
                })
                this.displayedImg = 'noImage'
                this.imgLoaded = true
                console.warn('unable to load WbArticleImage', this, error)
              }
              else {
                this.displayedImg = 'image'
                this.clearNoImageProperties()
                this.canvas?.requestRenderAll()
              }
              this.imgLoaded = true
              this.imgLoading = false
              console.log('image loaded')
            }, { crossOrigin: 'anonymous' })
          }
          else {
            this.setSrc(pngNoImage, () => this.canvas?.requestRenderAll())
            this.displayedImg = 'noImage'
            this.imgLoaded = true
            this.imgLoading = false
          }
        })
    }

    this.resourceBoxText.set({ text: this.articleNumber })

    if (this.selected) {
      if (this.articleNumber) {
        // const userStore = useUserStore()
        ctx.font = '50px Helvetica'
        const measure = ctx.measureText(this.articleNumber)
        // let seasonData = userStore.activeCatalog?.CatalogCode === this.article.CatalogCode
        //   ? userStore.activeCatalog.Season
        //   : userStore.linkedCatalogDetails[this.article.CatalogCode].Season
        // const seasonDataMeasure = ctx.measureText(seasonData)

        ctx.fillStyle = '#1484f8'
        const width = this.width || 0
        const height = this.height || 0
        ctx.fillRect(-width / 2, -height / 2, measure.width, 60)
        // ctx.fillRect(this.width! / 2 - seasonDataMeasure.width, -this.height! / 2, seasonDataMeasure.width, 60)
        ctx.fillStyle = '#FFFFFF'
        ctx.fillText(this.articleNumber, -width / 2, -height / 2 + 50)
      }
      this.resourceBoxRect.set({ stroke: '#2196f3' })
      // ctx.fillText(seasonData, this.width! / 2 - seasonDataMeasure.width, -this.height! / 2 + 50)
    }
    else {
      this.resourceBoxRect.set({ stroke: '#000000' })
    }

    if ((this.displayedImg === 'noImage' || this.displayedImg === 'articleDetails') && !this.isRequest) {
      this.imgLoading = false
      this.editableProps.addThumbnailAttributes.editable = true
      this.editableProps.backgroundColor.editable = true
      this.editableProps.border.editable = true
      this.editableProps.imageType.editable = false
      this.editableProps.scale.editable = false

      ctx.fillStyle = this.backgroundColor || 'transparent'
      ctx.fillRect(-this.width! / 2, -this.height! / 2, this.width!, this.height!)

      if (this.noImageObjectProperties.border) {
        const borderColor = this.noImageObjectProperties.border.borderColor || '#000000'
        const scaleFactor = this.get('borderScaleFactor') || 1
        const scaleValue = scaleFactor <= 6 ? 6 : scaleFactor // setting minimum border width as 6
        const strokeOffset = scaleValue / 2

        ctx.beginPath()
        ctx.roundRect(
          -this.width! / 2 + strokeOffset,
          -this.height! / 2 + strokeOffset,
          this.width! - scaleValue,
          this.height! - scaleValue,
          15,
        )
        ctx.lineWidth = scaleValue
        ctx.strokeStyle = borderColor
        ctx.stroke()
      }

      ctx.fillStyle = '#000'
      ctx.font = '30px Helvetica'
      ctx.textAlign = 'center'

      if (!this.cachedAttributeThumbnailValue) {
        this.loadTextValue()
      }
      if (this.cachedAttributeThumbnailValue && this.cachedAttributeThumbnailValue.length) {
        const wrappedLines = utils.getWrappedText(ctx, this.cachedAttributeThumbnailValue, this.width)
        // const attributeValueArray = this.cachedAttributeThumbnailValue.split('\n')
        const totalLines = wrappedLines.length
        const lineHeight = 35
        const centerY = 0
        let startY = 0
        if (this.width && ((totalLines * lineHeight) >= this.width)) {
          startY = centerY - (this.width / 2)
        }
        else {
          startY = centerY - ((totalLines - 1) / 2) * lineHeight
        }

        wrappedLines.forEach((text, index) => {
          ctx.fillText(text, 0, startY + index * lineHeight)
        })
        this.displayedImg = 'articleDetails'
      }
    }
    else {
      // hide noImageProperies
      this.editableProps.addThumbnailAttributes.editable = false
      this.editableProps.backgroundColor.editable = false
      this.editableProps.border.editable = false
      this.editableProps.imageType.editable = true
      this.editableProps.scale.editable = true
    }

    if (this.isRequest) {
      const fontColor = this.requestWaterMarkDetails.fontColor || '#5093e16e'
      const waterMarkText = this.requestWaterMarkDetails.waterMarkText || '#5093e16e'
      const text = waterMarkText.split(' ')
      ctx.lineWidth = 1
      ctx.fillStyle = fontColor
      ctx.font = '50px arial'
      ctx.rotate(-Math.PI / 4)
      ctx.textAlign = 'center'
      if (text.length === 1) {
        ctx.fillText(text, 0, 0)
      }
      else if (text.length === 2) {
        let y = 0
        text.forEach((value, index) => {
          if (index === 0) {
            y = -50
          }
          else if (index === 1) {
            y = 50
          }
          ctx.fillText(value, 0, y)
        })
      }
      else if (text.length === 3) {
        text.forEach((value, index) => {
          let y = 0
          if (index === 0) {
            y = -100
          }
          else if (index === 2) {
            y = 100
          }
          ctx.fillText(value, 0, y)
        })
      }
      else if (text.length === 4) {
        text.forEach((value, index) => {
          let y = 0
          if (index === 0) {
            y = -100
          }
          else if (index === 1) {
            y = -35
          }
          else if (index === 2) {
            y = 35
          }
          else if (index === 3) {
            y = 100
          }
          ctx.fillText(value, 0, y)
        })
      }
      else {
        ctx.fillText(text, 0, 0)
      }
    }
    else {
      if (!this.status) {
        ctx.lineWidth = 8
        ctx.beginPath()
        ctx.moveTo(-((this.width || 500) / 2), -((this.height || 500) / 2))
        ctx.lineTo((this.width || 500) / 2, (this.height || 500) / 2)
        ctx.strokeStyle = 'black'
        ctx.stroke()
        ctx.lineWidth = 5
        ctx.strokeStyle = 'red'
        ctx.stroke()
        ctx.lineWidth = 8
        ctx.beginPath()
        ctx.moveTo(((this.width || 500) / 2), -((this.height || 500) / 2))
        ctx.lineTo(-((this.width || 500) / 2), (this.height || 500) / 2)
        ctx.strokeStyle = 'black'
        ctx.stroke()
        ctx.lineWidth = 5
        ctx.strokeStyle = 'red'
        ctx.stroke()
      }
      let centerY = -(this.height || 500) / 2 + 14
      if (userStore.activeCatalog && ((!utils.isDefined(userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode]) || !utils.isDefined(userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode].showFavorites)) || userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode].showFavorites) && this.favColorList && this.favColorList.length) {
        const radius = 12
        for (let index = 0; index < this.favColorList.length; index++) {
          const centerX = index % 2 === 0 ? (index / 2 * 32) : -(index / 2 * 32) - 14
          const centerY = (-(this.height || 500) / 2) + 14
          ctx.beginPath()
          ctx.arc(centerX, centerY, radius, 0, (2 * Math.PI), false)
          ctx.fillStyle = this.favColorList[index]
          ctx.fill()
          if (index === 14) {
            break
          }
        }
        centerY += 32
      }
      if (this.colorCode !== '') {
        const radius = 18
        const centerX = 0

        ctx.beginPath()
        ctx.arc(centerX, centerY, radius, 0, (2 * Math.PI), false)
        ctx.fillStyle = this.colorCode
        ctx.fill()
      }
    }
  }

  clearNoImageProperties() {
    this.noImageObjectProperties = {}
    this.set('backgroundColor', 'transparent')
    this.set('borderColor', undefined)
    this.set('strokeWidth', 0)
    this.set('borderScaleFactor', 0)
    this.set('strokeDashArray', undefined)
    this.set('attributes', [])
  }
}

const f: any = fabric
f.WbArticleImage = WbArticleImage
