import tinycolor from 'tinycolor2'
import MbArticleDetails from '../merch/services/articleDetails'
import MbArticleImage from '../merch/services/articleImage'
import RenderHelper from '../whiteboard/services/renderHelper'
import { merchConstants, whiteboardConstants } from '@/models/constants'
import type { ImageObject, ShapeObject, TextObject } from '@/api/fileServer/model/generateModel'
import type { ShapeType } from '@/modules/whiteboard/services/shape'
import WbArticleDetails from '@/modules/whiteboard/services/articleDetails'
import WbModelDetails from '@/modules/whiteboard/services/modelDetails'
import WbModelImage from '@/modules/whiteboard/services/modelImage'
import WbArticleImage from '@/modules/whiteboard/services/articleImage'
import utils from '@/services/utils'
import type DuneAsset from '@/models/duneAsset'
import type MyArticle from '@/models/myArticle'

export async function toPptJson(wbObj: any, frame: any, staticImagePath: string, userStore, slideObjects: (ImageObject | ShapeObject | TextObject)[] = [], indexedCatalogArticleMap: Record<number, any> = {}, indexedCatalogModelNumberMap: Record<number, any> = {}, assetMap: Record<string, any> = {}, modelNumberToArticleNumberMap: Record<string, string> = {}, offsetCoords: Record<string, any> = { top: 0, left: 0, width: 0, height: 0, scaleX: 1, scaleY: 1, isGrouped: false },
) {
  if (wbObj && wbObj.type) {
    if (wbObj.type === whiteboardConstants.objectTypes.articleDetails
      || wbObj.type === whiteboardConstants.objectTypes.modelDetails
      || wbObj.type === whiteboardConstants.objectTypes.textBox) {
      let text = wbObj.text || ''
      if (wbObj.type === whiteboardConstants.objectTypes.articleDetails && indexedCatalogArticleMap[wbObj.catalogCode] && indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId]) {
        text = await WbArticleDetails.getText(indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId], wbObj.showLabels, wbObj.attributes, wbObj.attributesSeparator || '\n', wbObj.attributePlaceHolder || '', userStore.myAttributes)
      }
      else if (wbObj.type === whiteboardConstants.objectTypes.modelDetails && indexedCatalogModelNumberMap[wbObj.catalogCode] && indexedCatalogModelNumberMap[wbObj.catalogCode][wbObj.modelNumber]) {
        text = await WbModelDetails.getText(indexedCatalogModelNumberMap[wbObj.catalogCode][wbObj.modelNumber], wbObj.showLabels, wbObj.attributes, wbObj.attributesSeparator || '\n', wbObj.attributePlaceHolder || '')
      }
      slideObjects.push({
        type: 'text',
        content: {
          text,
          options: {
            x: getInches(wbObj.left - frame.left - (wbObj.angle === 90 ? (wbObj.width * wbObj.scaleX) / 2 : 0)),
            y: getInches(wbObj.top - frame.top + (wbObj.angle === 90 ? (wbObj.height * wbObj.scaleY) / 2 : 0)),
            w: getInches(wbObj.width * wbObj.scaleX),
            h: getInches(wbObj.height * wbObj.scaleY),
            align: wbObj.textAlign,
            bold: !!(wbObj.fontWeight && wbObj.fontWeight.toLowerCase() === 'bold'),
            underline: wbObj.underline ? { color: '#000000', style: 'sng' } : undefined,
            color: tinycolor(wbObj.fill).toHex(),
            fontFace: wbObj.fontFamily,
            fontSize: getInches(Number.parseInt((wbObj.fontSize * wbObj.scaleX).toFixed(0)) * 72),
            italic: !!(wbObj.fontStyle && wbObj.fontStyle.toLowerCase() === 'italic'),
            rotate: wbObj.angle,
            valign: 'top',
            margin: 0,
          },
        },
      } as TextObject)
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.sticky) {
      let fontSize = wbObj.fontSize
      if (fontSize < 0) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        fontSize = 100
        const h = wbObj.height || 0
        const w = wbObj.width || 0
        const xStart = -w / 2
        const yStart = -h / 2
        const margin = 10
        const fontStyle = `${(wbObj.italic ? 'italic' : '')} ${(wbObj.bold ? 'bold' : '')}`
        while (!RenderHelper.drawTextInBox(ctx!, wbObj.font, fontSize, fontStyle, wbObj.text, xStart + margin, yStart + margin, w - margin * 2, h - margin * 2, 'center', 'middle', true, false)) {
          fontSize--
          if (fontSize <= 0) { break }
        }
      }
      slideObjects.push({
        type: 'text',
        content: {
          text: wbObj.text,
          options: {
            x: getInches(wbObj.left - frame.left),
            y: getInches(wbObj.top - frame.top),
            w: getInches(wbObj.width * wbObj.scaleX),
            h: getInches(wbObj.height * wbObj.scaleY),
            align: 'center',
            fill: tinycolor(wbObj.fill).toHex(),
            color: tinycolor(wbObj.textColor).toHex(),
            fontFace: wbObj.font,
            fontSize: getInches(Number.parseInt((fontSize).toFixed(0)) * 72),
            bold: wbObj.bold,
            italic: wbObj.italic,
            rotate: wbObj.angle,
            valign: 'middle',
            margin: 0,
          },
        },
      } as TextObject)
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.articleImage
      || wbObj.type === whiteboardConstants.objectTypes.modelImage
      || wbObj.type === whiteboardConstants.objectTypes.image) {
      let src = wbObj.src
      if (wbObj.type === whiteboardConstants.objectTypes.articleImage && indexedCatalogArticleMap[wbObj.catalogCode] && indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId] && assetMap && assetMap[indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId].ArticleNumber]) {
        const assets: DuneAsset[] = assetMap[indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId].ArticleNumber] ? utils.sortAssets(assetMap[indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId].ArticleNumber], userStore.activeCatalog?.Config.NewestImageAssetKeyList) : []
        src = await WbArticleImage.getImageSrc(wbObj.catalogCode, wbObj.articleId, wbObj.objectId, wbObj.isRequest, wbObj.width, wbObj.height, assets, wbObj.assetKey)
      }
      else if (wbObj.type === whiteboardConstants.objectTypes.modelImage && modelNumberToArticleNumberMap[wbObj.modelNumber] && assetMap[modelNumberToArticleNumberMap[wbObj.modelNumber]] && assetMap) {
        const assets: DuneAsset[] = assetMap[modelNumberToArticleNumberMap[wbObj.modelNumber]] ? utils.sortAssets(assetMap[modelNumberToArticleNumberMap[wbObj.modelNumber]], userStore.activeCatalog?.Config.NewestImageAssetKeyList) : []
        src = WbModelImage.getImageSrc(wbObj.catalogCode, wbObj.width, wbObj.height, assets)
      }
      const staticImageTerms = ['noimg', 'loading']
      const isValidSrc = utils.isValidStringValue(src)
      const hasData = isValidSrc && src.toString().match(/^data:image\/(jpeg|png|gif|svg\+xml);base64,([A-Za-z0-9+/=]+)/)
      const data = hasData ? src : undefined
      if (wbObj.type === whiteboardConstants.objectTypes.articleImage && (!isValidSrc || staticImageTerms.some(term => src.toString().includes(term)))) {
        const fillColor = wbObj.noImageObjectProperties && wbObj.noImageObjectProperties.backgroundColor ? wbObj.noImageObjectProperties.backgroundColor : '#ffffff'
        const borderColor = wbObj.noImageObjectProperties && wbObj.noImageObjectProperties.border && wbObj.noImageObjectProperties.border.borderColor ? wbObj.noImageObjectProperties.border.borderColor : '#000000'
        const borderWidth = wbObj.noImageObjectProperties && wbObj.noImageObjectProperties.border && wbObj.noImageObjectProperties.border.strokeWidth ? wbObj.noImageObjectProperties.border.strokeWidth : 1
        const attributes = wbObj.noImageObjectProperties && wbObj.noImageObjectProperties.attributes ? wbObj.noImageObjectProperties.attributes : userStore.activeCatalog?.Config.WhiteboardThumbnailAttributes.length ? userStore.activeCatalog?.Config.WhiteboardThumbnailAttributes : ['ArticleNumber', 'ModelName']
        const text = wbObj.text ? wbObj.text : indexedCatalogArticleMap[wbObj.catalogCode] && indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId] ? await WbArticleImage.getText(indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId], attributes, '\n', '', userStore.myAttributes) : ''
        let fontSize = 18
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        do {
          ctx!.font = `${fontSize}px Helvetica`
          const textWidth = ctx!.measureText(text).width

          if (textWidth > (wbObj.width * wbObj.scaleX)) {
            fontSize -= 0.2 // Decrease font size if text overflows
          }
          else {
            break
          }
        } while (fontSize > 1) // Stop at a reasonable minimum size
        slideObjects.push({
          type: 'text',
          content: {
            text,
            options: {
              x: getInches(wbObj.left - frame.left),
              y: getInches(wbObj.top - frame.top),
              w: getInches(wbObj.width * wbObj.scaleX),
              h: getInches(wbObj.height * wbObj.scaleY),
              align: 'center',
              fill: fillColor,
              color: tinycolor('#000000').toHex(),
              line: {
                color: tinycolor(borderColor).toHex(),
                width: borderWidth,
              },
              fontFace: 'Helvetica',
              fontSize: Math.round(fontSize),
              wrap: true,
              rotate: wbObj.angle,
              valign: 'middle',
              rectRadius: 0.1,
              shape: 'roundRect',
            },
          },
        } as TextObject)
      }
      else {
        const path = !hasData ? (!isValidSrc || staticImageTerms.some(term => src.toString().includes(term)) ? staticImagePath : src) : undefined
        slideObjects.push({
          type: 'image',
          x: getInches((wbObj.left - frame.left) - (wbObj.angle === 90 ? (wbObj.width * wbObj.scaleX) / 2 : 0)),
          y: getInches(wbObj.top - frame.top + (wbObj.angle === 90 ? (wbObj.height * wbObj.scaleY) / 2 : 0)),
          w: getInches(wbObj.width * wbObj.scaleX),
          h: getInches(wbObj.height * wbObj.scaleY),
          path,
          data,
          rotate: wbObj.angle,
        } as ImageObject)
      }
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.shape) {
      const shapeType = wbObj.shapeType as ShapeType
      const type = shapeType === 'triangle'
        ? 'triangle'
        : shapeType === 'rectangle'
          ? 'rect'
          : shapeType === 'circle'
            ? 'ellipse'
            : shapeType === 'star'
              ? 'star'
              : 'line'
      slideObjects.push({
        type,
        x: getInches(wbObj.left - frame.left),
        y: getInches(wbObj.top - frame.top),
        w: getInches(wbObj.width * wbObj.scaleX),
        h: getInches(wbObj.height * wbObj.scaleY),
        color: wbObj.fill === '' ? 'transparent' : wbObj.fill,
        fill: wbObj.fill === ''
          ? undefined
          : {
              color: tinycolor(wbObj.fill).toHex(),
            },
        line: {
          color: tinycolor(wbObj.stroke).toHex(),
          width: wbObj.strokeWidth,
        },
        rotate: wbObj.angle,
      } as ShapeObject)
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.line) {
      slideObjects.push({
        type: 'line',
        x: getInches(wbObj.left - frame.left),
        y: getInches(wbObj.top - frame.top),
        w: getInches(wbObj.width * wbObj.scaleX),
        h: getInches(wbObj.height * wbObj.scaleY),
        fill: {
          color: tinycolor(wbObj.fill).toHex(),
        },
        line: {
          color: tinycolor(wbObj.stroke).toHex(),
          width: wbObj.strokeWidth,
        },
        rotate: wbObj.angle,
      } as ShapeObject)
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.group) {
      for (let i = 0; i < wbObj.objects.length; i++) {
        const groupObject = wbObj.objects[i]
        await toPptJson(groupObject, frame, staticImagePath, userStore, slideObjects, indexedCatalogArticleMap, indexedCatalogModelNumberMap, assetMap, modelNumberToArticleNumberMap, { top: offsetCoords.top + (wbObj.top * offsetCoords.scaleY), left: offsetCoords.left + (wbObj.left * offsetCoords.scaleX), width: wbObj.width * offsetCoords.scaleX, height: wbObj.height * offsetCoords.scaleY, scaleX: offsetCoords.scaleX * wbObj.scaleX, scaleY: offsetCoords.scaleY * wbObj.scaleY, isGrouped: true })
      }
    }
  }
  return slideObjects
}
export async function merchtoPptJson(mbObj: any, staticImagePath: string, userStore, indexedCatalogArticleMap: Record<number, MyArticle> = {}, assetMap: Record<string, DuneAsset[]> = {}, slideObjects: (ImageObject | ShapeObject | TextObject)[] = [], offsetCoords: Record<string, any> = { top: 0, left: 0, width: 0, height: 0, scaleX: 1, scaleY: 1, isGrouped: false }) {
  let obj: (ImageObject | ShapeObject | TextObject | undefined)
  if (mbObj && mbObj.type) {
    const groupLeft = offsetCoords.left
    const groupTop = offsetCoords.top
    const objectLeft = offsetCoords.isGrouped ? mbObj.left * offsetCoords.scaleX : mbObj.left
    const objectTop = offsetCoords.isGrouped ? mbObj.top * offsetCoords.scaleY : mbObj.top
    const x = getInches((groupLeft + objectLeft) - ((mbObj.width * mbObj.scaleX * offsetCoords.scaleX) / 2))
    const y = getInches((groupTop + objectTop) - ((mbObj.height * mbObj.scaleY * offsetCoords.scaleY) / 2))
    const w = getInches(mbObj.width * mbObj.scaleX * offsetCoords.scaleX)
    const h = getInches(mbObj.height * mbObj.scaleY * offsetCoords.scaleY)
    if (mbObj.type === merchConstants.objectTypes.articleDetails
      || mbObj.type === merchConstants.objectTypes.textBox) {
      let text = mbObj.text || ''
      if (mbObj.type === merchConstants.objectTypes.articleDetails && indexedCatalogArticleMap[mbObj.articleId]) {
        const customOptionsMap = {
          'ArticleName': 'ModelName',
          '_myPrices.wholesale': '_WholesalePrice',
          '_myPrices.retail': '_RetailPrice',
          '_myPrices.outlet': '_OutletPrice',
        }
        // need to convert the article name to model name as t1 studio web support model name only
        if (mbObj.customOptions && mbObj.customOptions.articleProps.length) {
          for (let index = 0; index < mbObj.customOptions.articleProps.length; index++) {
            const articleProp = mbObj.customOptions.articleProps[index]
            if (customOptionsMap.hasOwnProperty(articleProp)) {
              mbObj.customOptions.articleProps[index] = customOptionsMap[articleProp]
            }
          }
        }
        text = await MbArticleDetails.getText(indexedCatalogArticleMap[mbObj.articleId], mbObj.showLabels, mbObj.customOptions.articleProps, mbObj.customOptions.separator || '\n', mbObj.customOptions.attributePlaceHolder || '', userStore.myAttributes)
      }
      obj = {
        type: 'text',
        content: {
          text,
          options: {
            x: getInches(groupLeft + mbObj.left), // as origin in top left
            y: getInches(groupTop + mbObj.top),
            w,
            h,
            align: mbObj.textAlign,
            bold: !!(mbObj.fontWeight && mbObj.fontWeight.toLowerCase() === 'bold'),
            color: mbObj.fill ? tinycolor(mbObj.fill).toHex() : '000000',
            fontFace: mbObj.fontFamily,
            fontSize: getInches(Number.parseInt((mbObj.fontSize * mbObj.scaleX).toFixed(0)) * 72),
            italic: !!(mbObj.fontStyle && mbObj.fontStyle.toLowerCase() === 'italic'),
            rotate: mbObj.angle,
            valign: 'top',
            margin: 0,
            fill: mbObj.backgroundColor ? mbObj.backgroundColor.slice(1) : '',
            // currenty in merch link not supported  so whn supported add here as well
          },
        },
      } as TextObject
    }
    else if (mbObj.type === merchConstants.objectTypes.articleImage
      || mbObj.type === merchConstants.objectTypes.image) {
      let src = mbObj.data
      if (mbObj.type === merchConstants.objectTypes.articleImage) {
        let assets: DuneAsset[] = indexedCatalogArticleMap[mbObj.articleId] && assetMap[indexedCatalogArticleMap[mbObj.articleId].ArticleNumber] ? utils.sortAssets(assetMap[indexedCatalogArticleMap[mbObj.articleId].ArticleNumber], userStore.activeCatalog?.Config.NewestImageAssetKeyList) : []
        const newestImageAssetKeyList = userStore.activeCatalog?.Config.NewestImageAssetKeyList
        if (mbObj.imageType === 'newestImage' && newestImageAssetKeyList && newestImageAssetKeyList.length) {
          const newestImageAssetKeySet = new Set(newestImageAssetKeyList.map(x => x.toLowerCase()))
          if (assets.findIndex(a => utils.isDefined(a.Key) && newestImageAssetKeySet.has(a.Key.toLowerCase())) >= 0) {
            assets = assets.sort((a, b) => {
              const hasAKey = newestImageAssetKeySet.has(a.Key?.toLowerCase())
              const hasBKey = newestImageAssetKeySet.has(b.Key?.toLowerCase())

              if (hasAKey === hasBKey) {
                return new Date(b.UpdatedOn).getTime() - new Date(a.UpdatedOn).getTime()
              }

              return hasAKey ? -1 : 1
            })
          }
        }
        src = await MbArticleImage.getImageSrc(mbObj.articleId, mbObj.objectId, mbObj.isRequest, mbObj.width, mbObj.height, assets, mbObj.imageType)
      }
      const staticImageTerms = ['noimg', 'loading']
      const isValidSrc = utils.isValidStringValue(src)
      const hasData = isValidSrc && src.toString().match(/^data:image\/(jpeg|png|gif|svg\+xml);base64,([A-Za-z0-9+/=]+)/)
      const data = hasData ? src : undefined
      const path = !hasData ? (!isValidSrc || staticImageTerms.some(term => src.toString().includes(term)) ? staticImagePath : src) : undefined
      obj = {
        type: 'image',
        x,
        y,
        w,
        h,
        path,
        data,
        rotate: mbObj.angle,
        transparency: indexedCatalogArticleMap[mbObj.articleId] && !indexedCatalogArticleMap[mbObj.articleId].Status ? 50 : 0,
      } as ImageObject
    }
    else if (mbObj.type === merchConstants.objectTypes.rectangle
      || mbObj.type === merchConstants.objectTypes.triangle
      || mbObj.type === merchConstants.objectTypes.circle
      || mbObj.type === merchConstants.objectTypes.line) {
      // const shapeType = mbObj.type as ShapeType
      const type = mbObj.type === 'triangle'
        ? 'triangle'
        : mbObj.type === 'rectangle'
          ? 'rect'
          : mbObj.type === 'circle'
            ? 'ellipse'
            : 'line'
      obj = {
        type,
        x,
        y,
        w,
        h,
        fill: {
          color: tinycolor(mbObj.fill).toHex(),
        },
        line: {
          color: tinycolor(mbObj.stroke).toHex(),
          width: mbObj.strokeWidth,
        },
        rotate: mbObj.angle,
      } as ShapeObject
    }
    else if (mbObj.type === merchConstants.objectTypes.group) {
      for (let i = 0; i < mbObj.objects.length; i++) {
        const groupObject = mbObj.objects[i]
        await merchtoPptJson(groupObject, staticImagePath, userStore, indexedCatalogArticleMap, assetMap, slideObjects, { top: offsetCoords.top + mbObj.top, left: offsetCoords.left + mbObj.left, width: offsetCoords.width + mbObj.width, height: offsetCoords.height + mbObj.height, scaleX: offsetCoords.scaleX * mbObj.scaleX, scaleY: offsetCoords.scaleY * mbObj.scaleY, isGrouped: true })
      }
    }
  }
  if (obj) {
    slideObjects.push(obj)
  }
  return slideObjects
}
function getInches(value: number) {
  return value / 96
}
